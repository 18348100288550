import Header from '../components/Header/Header'
import { Link } from 'react-router-dom'

function Route404() {
    return (
        <div>
            <Header />
            <h1>Contenido no encontrado, <Link to="/">volver al inicio.</Link></h1>
        </div>

    )
}

export default Route404