import "./Confirmation.css"

function Confirmation({children, action, showConfirmation, setShowConfirmation}){

  //effectue l'action si click sur confirmation, si click en dehors du popup ou sur le bouton deny, masque le popup
  //(en utilisant les useState parents qu'on passe en props)

  function confirm(e){
      e.stopPropagation();
      setShowConfirmation(false);
      action();
  }

  function hidePopup(){
      setShowConfirmation(false);
  }
    
  return(
      showConfirmation && 
      <div className="confirmationPopupContainer" onClick={hidePopup}>
          <div className="confirmationPopup" onClick={(e) => e.stopPropagation()}>
              <h3>{children}</h3>
              <p>
                <span className="confirm" onClick={confirm}>SI</span>
                <span className="deny" onClick={hidePopup}>NO</span>
              </p>
          </div>
      </div>
  )
}

export default Confirmation;