import {Navigate} from 'react-router-dom'

function Home() {
    const role = localStorage.getItem('role');
    const token = localStorage.getItem('token');

    if(!role || !token)
    {
        return <Navigate to="/login" replace />
    }
    else if(role==="admin" || role==="despacho"){
        return <Navigate to="/despacho" replace />
    }
    else if(role==="almacen"){
        return <Navigate to="/almacen" replace />
    }
}

export default Home