import "./Naves.css"
import Header from "../../Header/Header";
import { handleAxiosError } from "../../../utils/utils";
import axios from "axios";
import { apiRoot } from "../../../utils/utils";
import { useEffect, useState } from "react";
import { ReactComponent as DeleteIcon } from "../../../assets/images/delete.svg"
import { ReactComponent as AddIcon } from "../../../assets/images/add_circle.svg"
import { ReactComponent as Loader } from "../../../assets/images/loader.svg"
import Confirmation from "../../../utils/Confirmation";
import { compare } from "../../../utils/utils";


function Naves() {

    const [naves, setNaves] = useState(false);
    const [isMainLoading, setMainLoading] = useState(false);
    const [addNaveLoader, setAddNaveLoader] = useState(false);

    useEffect(() => {
        setMainLoading(true);
        axios(apiRoot+"/wp/v2/naves")
        .then(function (response) {
            console.log("Chargement naves response:",response.data);
            response.data.sort(compare);
            setNaves(response.data);
            setMainLoading(false);
        })
        .catch(function (error) {
            handleAxiosError(error);
            setMainLoading(false);
        });
    }, [])

    function addNave(e){
        e.preventDefault();
        setAddNaveLoader(true);
        axios.post(apiRoot+"/wp/v2/naves",{ //fields = acf
            title:document.getElementById("newNaveName").value,
            status:"publish"
        })
        .then(function (response) {
            console.log("Add nave response:",response);
            let copyNaves = naves;
            copyNaves = [...naves, response.data];
            setNaves([...copyNaves]);
            setAddNaveLoader(false);
        })
        .catch(function (error) {
            handleAxiosError(error);
            setAddNaveLoader(false);
        });
    }

    return (
        <div>
            <Header>Naves</Header>

            {
                isMainLoading ? 
                <Loader id="mainLoader" />:
                naves &&
                    <div id="naves">
                        <div id="navesContainer">
                            {
                                naves.map((nave, index) => (
                                    <ListNaves itemId={nave.id} arrayId={index} title={nave.title.rendered} acf={nave.acf} key={nave.id} naves={naves} setNaves={setNaves} />
                                ))
                            }
                        </div>
                        {
                            addNaveLoader ?
                            <Loader /> :
                        
                            <form id="addNave" onSubmit={addNave}>
                                <input id="newNaveName" type="text" placeholder="Añadir una nave"/>
                                <AddIcon type="submit" onClick={addNave}/>
                            </form>
                        }
                    </div>   
            }
            
        </div>
    )
}

function ListNaves({itemId, title, acf, naves, setNaves, arrayId}) {

    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

    const calles = acf.calles;

    function updateNave(e){
        axios.put(apiRoot+"/wp/v2/naves/"+itemId,{
            title:e.target.value
        })
        .then(function (response) {
            console.log("Update nave response:",response);
        })
        .catch(function (error) {
            handleAxiosError(error);
        });
    }

    function deleteNave(){
        //demander confirmation avant suppression
        axios.delete(apiRoot+"/wp/v2/naves/"+itemId)
        .then(function (response) {
            console.log("Delete nave response:",response);
            naves.splice(arrayId, 1);
            setNaves([...naves]); //ici on utilise le spread operator plutôt que de mettre directement naves parceque sinon ça serait le même tableau et react n'aurait pas fait de changement du DOM
        })
        .catch(function (error) {
            handleAxiosError(error);
        });
    }

    return (
      <div className="nave" id={itemId}>
        <input type="text" className="calleTitle" defaultValue={title} onBlur={updateNave}/>        
        <DeleteIcon className="delete" onClick={() => {setShowDeleteConfirmation(true)}}/>
        
        <Confirmation action={deleteNave} showConfirmation={showDeleteConfirmation} setShowConfirmation={setShowDeleteConfirmation}>
            Suprimir {title} ?
        </Confirmation>
        <div>
            <h4>Calles :</h4>
            <ListCalles naveId={itemId} calles={calles} />
        </div>
      </div>
    );
  }

function ListCalles(props){
    const [calles, setCalles] = useState(props.calles);

    function updateCalleInCalles(e){
        const newValue = e.target.value;
        const callesCopy = calles;
        callesCopy[e.target.id].calle = newValue;
        setCalles([...callesCopy]);
    }

    function deleteCalleInLocalState(id){
        const callesCopy = calles;
        callesCopy.splice(id,1);
        setCalles([...callesCopy]);
    }

    function updateOrDeleteCalles(e){
        const idCalleInArray = e.target.id;
        const newValue = e.target.value;
        if(newValue==="")
        {
            deleteCalleInLocalState(idCalleInArray);
        }
        axios.put(apiRoot+"/wp/v2/naves/"+props.naveId,{
            fields:{ //attention quand on veut mettre à jour un champs acf il ne faut pas l'appeler acf mais field
                calles:calles
            }
        })
        .then(function (response) {
            console.log("Update or delete calle response:",response.data.acf.calles);
        })
        .catch(function (error) {
            handleAxiosError(error);
        });
        
    }

    const [addCalleLoader, setAddCalleLoader] = useState(false);

    function addCalle(e){
        e.preventDefault();
        setAddCalleLoader(true);
        let newCalleValue = {calle: document.getElementById(e.target.dataset.idbutton).value};
        let newFieldValueToAdd = [];

        if(calles){
            newFieldValueToAdd=[...calles,newCalleValue]
        }
        else{
            newFieldValueToAdd=[newCalleValue]
        }

        axios.put(apiRoot+"/wp/v2/naves/"+props.naveId,{
            fields:{
                calles:newFieldValueToAdd
            }
        })
        .then(function (response) {
            console.log("Put calle response:",response);
            setCalles(newFieldValueToAdd);
            setAddCalleLoader(false);
        })
        .catch(function (error) {
            handleAxiosError(error);
            setAddCalleLoader(false);
        });
    }

    const stringNave = "nave";
    
    return(
        <>
            <div className="callesContainer">
            {
                calles&&
                calles.map((calle, i) => {
                    return <input className="calle" key={calle+i} id={i} type="text" value={calle.calle} onChange={updateCalleInCalles} onBlur={updateOrDeleteCalles}/>
                })
            }
            </div>
            {
                addCalleLoader ? 
                    <Loader /> :
                    <form onSubmit={addCalle} data-idbutton={stringNave+props.naveId}>
                        <input id={stringNave+props.naveId} className="newCalleName calle" placeholder="Añadir calle" type="text"/><AddIcon type="submit" onClick={addCalle} data-idbutton={stringNave+props.naveId} />
                    </form>
            }
        </>
    )
}

export default Naves