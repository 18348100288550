import "./Salida.css"
import Header from "../../components/Header/Header";
import axios from "axios";
import { apiRoot, handleAxiosError, compare } from "../../utils/utils";
import { useState, useEffect } from "react";
import Scan from "../../components/almacen/Scan/Scan";
import Confirmation from "../../utils/Confirmation";
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from "react-router-dom";
import {ReactComponent as Loader} from "../../assets/images/loader.svg";


let paletToQuit = {
  idPalet:false,
  productoId:false,
  productoName:false,
  codigoBarra:false,
  cantidad:false,
  nave:false,
  naveName:false,
  calleName:false,
  medidas:false
}

function Salida({codigoFromProps}) {//codigo reçoit une string qui doit être un code barre existant

    //chargement initial des produits au lancement du component :
    const [productos, setProductos] = useState(false);
    const [naves, setNaves] = useState(false);

    useEffect(()=>{
      directSalida();  
    },[])

    /*//on récupère les infos des produits
    async function getProductos(){
      await axios(apiRoot+"/wp/v2/productos?_fields=title,id,acf.medidas,acf.elemento,acf.ubicaciones")
      .then(function (response) {
          console.log("Chargement Productos response:" , response.data);
          response.data.sort(compare);
          setProductos(response.data);
          return null;
      })
      .catch(function (error) {
          handleAxiosError(error);
          return null;
      });
    }

    //on récupère les infos des naves
    async function getNaves(){
      await axios(apiRoot+"/wp/v2/naves?_fields=title,id,acf.calles")
      .then(function (response) {
          console.log("Chargement Naves response:", response.data);
          setNaves(response.data);
          return null;
      })
      .catch(function (error) {
          handleAxiosError(error);
          return null;
      });
    }*/

    async function getProductosAndNaves(){
      async function getNaves(perPage, offset) {
        const response = await axios.get(apiRoot+"/wp/v2/naves?_fields=title,id,acf.calles&per_page="+perPage+"&offset="+offset);
        console.log("Chargement naves response:" , response.data);
        return response.data;
      }

      async function fetchNaves() {
          const perPage = 100;
          let offset = 0;
          let allPosts = [];
          let newPosts = await getNaves(perPage, offset);
          while (newPosts.length > 0) {
            allPosts = [...allPosts, ...newPosts];
            offset += perPage;
            newPosts = await getNaves(perPage, offset);
          }
          setNaves(allPosts);
          console.log("Fin de chargement des naves:" , allPosts);
        }
        fetchNaves();

        //récupération des produits
        async function getProductos(perPage, offset) {
            const response = await axios.get(apiRoot+"/wp/v2/productos?_fields=title,id,acf.medidas,acf.elemento,acf.ubicaciones&per_page="+perPage+"&offset="+offset);
            console.log("Chargement Productos response:" , response.data);
            return response.data;
        }
        async function fetchProductos() {
          const perPage = 100;
          let offset = 0;
          let allPosts = [];
          let newPosts = await getProductos(perPage, offset);
          while (newPosts.length > 0) {
            allPosts = [...allPosts, ...newPosts];
            offset += perPage;
            newPosts = await getProductos(perPage, offset);
          }
          setProductos(allPosts);
          console.log("Fin de chargement des productos:" , allPosts);
        }
        fetchProductos();
    }

    async function directSalida(){
      if(codigoFromProps && codigoFromProps!==""){
        console.log(codigoFromProps)
        getProductosAndNaves()
        .then(
          updateCodigoBarra()
        );
      }
      else{
        getProductosAndNaves();
      }    
    }

    const [scanResult, setScanResult] = useState("");
    const [codigoBarraExistante, setCodigoBarraExistante] = useState(false);
    const [directSalidaAllowed, setDirectSalidaAllowed] = useState(true);
    function updateCodigoBarra(e){
      let matchCodigoBarra = false;
      paletToQuit.codigoBarra = paletToQuit.codigoBarra?false:true; //pour l'objet global qui sera renvoyé à la fin
      if(e.target.value!=="" || codigoFromProps && directSalidaAllowed){ //si le code barre rentré dans l'input n'est pas vide
        //on change le value de l'input dans le useState:
        if(codigoFromProps && codigoFromProps!==""){
          setScanResult(codigoFromProps);
        }
        else{
          setScanResult(e.target.value);
        }
        setCodigoBarraExistante(false);
        productos.map(producto => { //on fait une recherche pour voir si ce code barre existe dans les produits
          if(producto.acf.ubicaciones && producto.acf.ubicaciones.length>0){
            producto.acf.ubicaciones.map(palet => { //recherche par code barre
              if(palet.codigo_barra.toLowerCase()==e.target.value.toLowerCase()){
                console.log('CODE BARRE EXISTANT dans la base de données : '+palet.codigo_barra.toLowerCase()+", code barre renseigné : "+e.target.value.toLowerCase());
                paletToQuit.codigoBarra = palet.codigo_barra;
                paletToQuit.productoId= producto.id;
                paletToQuit.productoName= producto.title.rendered;
                paletToQuit.medidas= producto.acf.medidas;
                paletToQuit.cantidad=palet.cantidad;
                paletToQuit.nave=palet.nave_id;
                paletToQuit.idPalet=palet.id_palet;
                naves.map(nave => { //récupération du nom de la nave
                  if(nave.id == palet.nave_id){
                    paletToQuit.naveName = nave.title.rendered
                  }
                });
                paletToQuit.calleName=palet.calle;
                console.log(paletToQuit);
                setCodigoBarraExistante(true);
                matchCodigoBarra=true;
                setFormErrors(false);
              }
            })
          }
        });        
      }
      else{ //si le code barre est vide
        setScanResult("");
        setDirectSalidaAllowed(false);
      }
      if(matchCodigoBarra==false)
      {
        //aucun code trouvé, signalé via erreur
        setFormErrors(["Ningun palet encontrado con este código de barras"]);
        setDirectSalidaAllowed(false);
      }
    }

    let [creationSucess, setCreationSuccess] = useState(false);
    const [formErrors, setFormErrors] = useState();
    const [showConfirmation, setShowConfirmation] = useState(false);
    const navigate = useNavigate();
    function askConfirmation(e){
      e.preventDefault();
      setShowConfirmation(true);
      setDirectSalidaAllowed(false);//on enlève la possibilité de faire une sortie directe (il faut que ça soit proposé une fois mais que sinon l'interface de salida soit normale)
    }

    function removePaletFromDb(){

      let newUbicaciones = {
        id_palet:uuidv4(),
        codigo_barra:paletToQuit.codigoBarra,
        cantidad:paletToQuit.cantidad,
        nave_id:paletToQuit.nave,
        calle:paletToQuit.calle
      }

      console.log(newUbicaciones);

      async function removePalet() {
        try {
          const currentPalet = await axios.get(apiRoot+"/wp/v2/productos/"+paletToQuit.productoId+"?_fields=acf.ubicaciones")
          .then(function (response) {
              console.log("Get des current palettes/ubicaciones:",response);
              return response;
          });

          async function postNewPalet(){
            if(Array.isArray(currentPalet.data.acf.ubicaciones)&&currentPalet.data.acf.ubicaciones.length>0){ //si une ou plusieurs palettes sont existantes
              const indexPaletToRemove = currentPalet.data.acf.ubicaciones.findIndex(paletTemp => paletTemp.id_palet == paletToQuit.idPalet);
              currentPalet.data.acf.ubicaciones.splice(indexPaletToRemove,1);
              newUbicaciones = currentPalet.data.acf.ubicaciones;
              axios.put(apiRoot+"/wp/v2/productos/"+paletToQuit.productoId,{ //fields = acf
                fields:{
                    ubicaciones:newUbicaciones
                }
              })
              .then(function (response) {                
                  console.log("Deleting palet response:",response);
                  //on récupère le productos et on vient lui enlever la palette qui a été modifiée
                  const indexProductoToChange = productos.findIndex(producto => producto.id == paletToQuit.productoId);
                  productos[indexProductoToChange].acf.ubicaciones.splice(indexPaletToRemove,1);
                  setProductos(productos);
                  //reset des inputs et variables
                  function resetInputs(){
                    setScanResult("");
                    paletToQuit = {
                      idPalet:false,
                      productoId:false,
                      productoName:false,
                      codigoBarra:false,
                      cantidad:false,
                      nave:false,
                      naveName:false,
                      calleName:false,
                      medidas:false
                    }
                    setCodigoBarraExistante(false);
                  }
                  resetInputs();
                  setFormErrors(false);
                  setCreationSuccess(true); //on affiche l'écran de succès
                  //on joue un son
                  setTimeout(() => {
                      setCreationSuccess(false); //on masque l'écran de succès 2 sec plus tard
                      //on redirige
                      navigate("/");
                  }, 1500);
              })
              .catch(function (error) {
                handleAxiosError(error);
                setFormErrors(error.message); 
              });
            }
          }
          const newPalet = await postNewPalet();
        } catch (error) {
          handleAxiosError(error);
          setFormErrors(error.message); 
        }
      }
      removePalet();
    }

    return (
      <div id="almacen" className="centered">
          <Header>Salida</Header>
          <h1>Salida de palet</h1>

          {
            productos&&naves ?
            (
              <form onSubmit={(e) => askConfirmation(e)}>            
                <h4 className="label">Entrar / escanear código de barras:</h4>
                <input id="codigoBarra" value={scanResult} onChange={e => updateCodigoBarra(e)} onBlur={e => updateCodigoBarra(e)}></input>
                <Scan setScanResult={setScanResult} hideText={true} />
                {
                  codigoBarraExistante&&(
                    <>
                      <h3>Palet encontrado :</h3>
                      <p>{paletToQuit.medidas}, {paletToQuit.productoName}</p>
                      <p><b>{paletToQuit.naveName}, {paletToQuit.calleName}</b></p>
                      <p className="blue">{paletToQuit.cantidad} planchas</p>              
                      <br />
                      <button id="submitWholeForm" type="submit" onClick={askConfirmation}>Salida de palet</button>
                    </>
                  )
                }
              </form>
            ):
              <Loader id="mainLoader" />
            }
          {
            formErrors&&(
              <>
              <p className="error">
                {
                  formErrors.map((err,i)=>
                    {
                      return(
                        <span key={"a"+i}>
                          {err}<br />
                        </span>
                      )
                    }
                  )
                }
              </p>
              </>
            )
          }
          <Confirmation action={removePaletFromDb} showConfirmation={showConfirmation} setShowConfirmation={setShowConfirmation}>
                Confirmar la salida de:
                <>
                  <br /><br />
                  <span>Código de barras:<br /> <span>{paletToQuit.codigoBarra}</span></span><br /><br />
                  <span>Producto:<br /> <span>{paletToQuit.medidas}, {paletToQuit.productoName}</span></span><br /><br />
                  <span>Ubicación:<br /> <span>{paletToQuit.naveName}, {paletToQuit.calleName}</span></span><br />
                </>
          </Confirmation>
          {creationSucess && <div className="popup"><h2 style={{color: "green"}}>Salida OK</h2></div>}
      </div>
    );
  }
  
  export default Salida;