import { Link } from "react-router-dom";

const compareNaves = (a,b) => {
    if ( a[1].nave < b[1].nave ){
        return -1;
    }
    if ( a[1].nave > b[1].nave ){
        return 1;
    }
    return 0;
}
const compareCalles = (a,b) => {
    if ( a.calle < b.calle ){
        return -1;
    }
    if ( a.calle > b.calle ){
        return 1;
    }
    return 0;
}

function Ubicaciones({ubicaciones,naves, isHtmlBlock, producto}){

    let tempNaves = [];
    if(ubicaciones && naves)
    {
        for(let i=0;i<ubicaciones.length;i++){
            const isExistingNave = tempNaves.find(element => element[0]==ubicaciones[i].nave_id);
            
            if(isExistingNave){ //si entrée de tableau avec cette nave :
                const isExistingCalle = isExistingNave[1].calles.find(element => element.calle == ubicaciones[i].calle);
                if(isExistingCalle){ //si la calle existe déjà, on change la quantité et on ajoute le codigo de barra
                    isExistingCalle.cantidad = Number(isExistingCalle.cantidad)+Number(ubicaciones[i].cantidad);
                    isExistingCalle.codigos_barras.push({
                        codigo:ubicaciones[i].codigo_barra,
                        cantidad:ubicaciones[i].cantidad,
                        autor:ubicaciones[i].autor ? ubicaciones[i].autor : "Desconocido"
                    });
                }
                else{
                    //si la calle n'existe pas on la push dans le tableau
                    isExistingNave[1].calles.push({
                        calle:ubicaciones[i].calle,
                        cantidad:ubicaciones[i].cantidad,
                        codigos_barras:[{
                            codigo:ubicaciones[i].codigo_barra,
                            cantidad:ubicaciones[i].cantidad
                        }]
                    })
                } 
            }
            else{ //si pas d'entrée de tableau avec cette nave on ajoute toutes les infos de la nave et la calle
                tempNaves.push([
                    ubicaciones[i].nave_id,
                    {
                        nave:naves.find(element => element.id == ubicaciones[i].nave_id) ? naves.find(element => element.id == ubicaciones[i].nave_id).title.rendered : "????",
                        calles:[{
                            calle:ubicaciones[i].calle,
                            cantidad:ubicaciones[i].cantidad,
                            codigos_barras:[{
                                codigo:ubicaciones[i].codigo_barra,
                                cantidad:ubicaciones[i].cantidad,
                                autor:ubicaciones[i].autor
                            }],
                        }]
                    }                    
                ])
            }
        }
        //on trie chacun des éléments du tempNaves pour classer les calles par ordre alphabétique
        tempNaves.forEach(element => {
            element[1].calles.sort(compareCalles);
        });
        //on trie les naves par ordre alphabétique
        tempNaves.sort(compareNaves);
        if(isHtmlBlock)
        {
            return (
                tempNaves.map(ubicacion => (
                    <div className="ubicacion">
                        <h3 key={ubicacion[0]}>
                            <span className="naveTitle">
                                {ubicacion[1].nave + ": "}
                            </span>
                        </h3>
                        <Calles calles={ubicacion[1].calles} isHtmlBlock={isHtmlBlock} />
                    </div>
                    
                ))
            )
        }
        else{
            return (
                <span>
                    {
                        tempNaves.map(ubicacion => (
                            <span className="ubicacion" key={ubicacion[0]}>
                                <span className="naveTitle">
                                    {ubicacion[1].nave + ": "}
                                    <Calles calles={ubicacion[1].calles} isHtmlBlock={isHtmlBlock} naveName={ubicacion[1].nave} producto={producto} />
                                </span>
                            </span>
                        ))
                    }                
                </span>
            )
        }
    }
}

function showCodigosDetailsPopup(e){
    e.target.nextElementSibling.style.display ="flex";
}
function hideCodigosDetailsPopup(e){
    e.stopPropagation();
    e.target.style.display ="none";
}

function avoidPropagation(e)
{
    e.stopPropagation();
}

function Calles({calles, isHtmlBlock, naveName, producto}){
    if(isHtmlBlock)
    {
        return(
            calles.map(calle => (
                <p className="calle" key={calle.calle}>
                    {calle.calle}
                    <span className="cantidad">{" (" + calle.cantidad + ") "}</span>
                </p>
            ))
        )
    }
    else{
        function copyText(text){
            navigator.clipboard.writeText(text);
        }
        return(
            calles.map(calle => (
                <span className="calle" key={calle.calle}>
                    {calle.calle}
                    <span className="cantidad" title="Mostrar códigos de barras" onClick={(e)=>{showCodigosDetailsPopup(e)}}>{" (" + calle.cantidad + ") "}</span>
                        <div 
                            onClick={(e)=>{hideCodigosDetailsPopup(e)}}
                            style={{
                                position:"fixed",
                                width:"100vw",
                                height:"100vh",
                                left:"0",
                                top:"0",
                                display:"none",
                                justifyContent:"center",
                                alignItems:"center",
                                backgroundColor:"rgba(0,0,0,0.34)",
                                zIndex:"1"
                            }}
                            className="popupCodigosDetailsContainer"
                        >
                            <div 
                                style={{
                                    backgroundColor:"white",
                                    padding:"20px"
                                }}
                                onClick={(e)=>{avoidPropagation(e)}}
                            >
                                <h3 style={{textAlign:"center"}} className="blue">{naveName} - {calle.calle}</h3>
                                <p style={{textAlign:"center"}}>Códigos de barras:</p>
                                <ul>
                                    {
                                        calle.codigos_barras.map((codigo) => (
                                            <li key={codigo.codigo}>{codigo.codigo} <span className="blue">({codigo.cantidad})</span><span style={{cursor:"pointer"}} onClick={()=>navigator.clipboard.writeText(codigo.codigo)} title="Copiar código de barras">📝</span><Link title="Imprimir código de barras" target="_blank" to={"/impresion/qr?qr="+codigo.codigo+"&ubicacion="+naveName+" - "+calle.calle+"&producto="+producto+"&cantidad="+codigo.cantidad}>🖨️</Link> <span style={{fontWeight:'400',color:'#888'}}>{codigo.autor}</span></li>
                                        ))
                                    }
                                </ul>
                            </div>
                        </div>                 
                </span>
            ))
        )
    }
}

export default Ubicaciones;