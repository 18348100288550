import axios from "axios";

export const apiRoot = "https://back.indalstock.es/wp-json";
//export const apiRoot = "http://localhost/almacenBackend/wp-json";

export const saveAuthToken = token => {
    if (token) {

        axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

        if(localStorage.getItem("token")!==token){
            localStorage.removeItem("token");
            localStorage.setItem("token",token);
        }
        console.log("Token placé dans header axios & localStorage");
        return false;
    }
  }

export const removeAuthToken = (explication) => {
    delete axios.defaults.headers.common["Authorization"];
    localStorage.removeItem("token");
    localStorage.removeItem("role");
    localStorage.removeItem("user");
    console.log("Token enlevé du header axios & du localStorage");
    console.log("TODO : Problème d'identification, vérifier qu'après ce message une redirection soit bien faite vers la page de login");
    if(explication){
        console.log(explication);
        window.location = "/#/login?rejectedFor="+explication;
        //ajouter .replace pour debugger
    }
    else{
        window.location = "/#/login";
        //ajouter .replace pour debugger
    }
}

export const handleAxiosError = (error) => {
    if (error.response) {
        if(error.code=="ERR_NETWORK"){
            console.error("Erreur ERR_NETWORK, le serveur backend est-il en ligne ?");
            window.location = "/#/login?rejectedFor="+"le_serveur_backend_est_il_en_ligne?";
        }
        else if(error.response.status===403){
            console.error(error.response.data.code + ", error details:");
            console.log(error);
            if(error.response.data.code=="jwt_auth_failed")
            {
                removeAuthToken("403auth-invalidPassOrUser");
            }
            else if(error.response.data.code=="jwt_auth_invalid_token")
            {
                if(error.response.data.message=="Expired token")
                {
                    removeAuthToken("403auth-expiredToken");    
                }
                else{
                    removeAuthToken("403auth-invalidToken");
                }                
            }
            else{
                removeAuthToken("403auth-utilJsAddReplaceToRedirectionForDEBUG");
            }
            return error.response.data.message;
        }
        else if(error.response.status===401){
            console.error(error.response.data.code + ", error details: " + error + " Plus de détails en suivant.");
            console.log(error);
            removeAuthToken("401authorization-laSessionEstElleExpiree");
            return error.response.data.message;
        }
        else{
            console.error("The request was made and the server responded with a status code that falls out of the range of 2xx, error details:");
            console.log(error.response);
        }
    }
    else if (error.request) {
            console.error('The request was made but no response was received, error details:');
            console.log(error.request.status);
    }
    else {
        console.error("Something happened in setting up the request that triggered an Error, error details:");
        console.log(error.message);
    }
    console.log("Whole Axios error :",error);
    return "Error de connexión";
}

export const compare = (a,b) => {
    if ( a.title.rendered < b.title.rendered ){
        return -1;
    }
    if ( a.title.rendered > b.title.rendered ){
        return 1;
    }
    return 0;
}

export const compareMedidas = (a,b) => {
    if ( a.acf.medidas < b.acf.medidas ){
        return -1;
    }
    if ( a.acf.medidas > b.acf.medidas ){
        return 1;
    }
    return 0;
}