import "./Stock.css"
import Header from "../../Header/Header"
import { useState, useEffect } from "react"
import axios from "axios"
import { apiRoot, compareMedidas, handleAxiosError } from "../../../utils/utils"
import Ubicaciones from "./Ubicaciones"
import Confirmation from "../../../utils/Confirmation"
import Movimientos from "./Movimientos"
import { ReactComponent as Loader } from "../../../assets/images/loader.svg"
import IncidenciasPopup from "./IncidenciasPopup"
import ImpresionesPopup from "./ImpresionesPopup"

function Stock() {

    const [naves, setNaves] = useState(false);
    const [productos, setProductos] = useState(false);
    const [filteredProductos, setFilteredProductos] = useState(false);
    const [incidencias, setIncidencias] = useState(false);
    const [impresiones, setImpresiones] = useState(false);

    useEffect(()=>{
        //on récupère les infos des naves
        async function getNaves(perPage, offset) {
            const response = await axios.get(apiRoot+"/wp/v2/naves?_fields=title,id&per_page="+perPage+"&offset="+offset);
            console.log("Chargement naves response:" , response.data);
            return response.data;
        }

        async function fetchNaves() {
          const perPage = 100;
          let offset = 0;
          let allPosts = [];
          let newPosts = await getNaves(perPage, offset);
          while (newPosts.length > 0) {
            allPosts = [...allPosts, ...newPosts];
            offset += perPage;
            newPosts = await getNaves(perPage, offset);
          }
          setNaves(allPosts);
          console.log("Fin de chargement des naves:" , allPosts);
        }
        fetchNaves();

        //on récupère les incidencias
        async function getIncidencias(perPage, offset) {
            const response = await axios.get(apiRoot+"/wp/v2/incidencias?_fields=title,id,acf.producto_complete_name,acf.cantidad,acf.tipo,acf.lote,acf.idPalet,acf.date&per_page="+perPage+"&offset="+offset);
            console.log("Chargement incidencias response:" , response.data);
            return response.data;
        }

        async function fetchIncidencias() {
          const perPage = 100;
          let offset = 0;
          let allPosts = [];
          let newPosts = await getIncidencias(perPage, offset);
          while (newPosts.length > 0) {
            allPosts = [...allPosts, ...newPosts];
            offset += perPage;
            newPosts = await getIncidencias(perPage, offset);
          }
          if(allPosts.length>0)
          {
              setIncidencias(allPosts);          
          }
          else{
              setIncidencias(false);
          }
          console.log("Fin de chargement des Incidencias:" , allPosts);
        }
        fetchIncidencias();

        //on récupère les impresiones

        async function getImpresiones(perPage, offset) {
            const response = await axios.get(apiRoot+"/wp/v2/impresiones?_fields=id,acf.barCode,acf.producto,acf.ubicacion,acf.cantidad&per_page="+perPage+"&offset="+offset);
            console.log("Chargement impresiones response:" , response.data);
            return response.data;
        }

        async function fetchImpresiones() {
          const perPage = 100;
          let offset = 0;
          let allPosts = [];
          let newPosts = await getImpresiones(perPage, offset);
          while (newPosts.length > 0) {
            allPosts = [...allPosts, ...newPosts];
            offset += perPage;
            newPosts = await getImpresiones(perPage, offset);
          }
          if(allPosts.length>0)
          {
              setImpresiones(allPosts);          
          }
          else{
              setImpresiones(false);
          }
          console.log("Fin de chargement des impresiones:" , allPosts);
        }
        fetchImpresiones();

        //récupération des produits
        async function getProductos(perPage, offset) {
            const response = await axios.get(apiRoot+"/wp/v2/productos?_fields=title,id,acf.medidas,acf.elemento,acf.ubicaciones,acf.stock_almacen,acf.stock,acf.movimientos&filter&per_page="+perPage+"&offset="+offset);
            console.log("Chargement Productos response:" , response.data);
            return response.data;
        }

        async function fetchProductos() {
          const perPage = 100;
          let offset = 0;
          let allPosts = [];
          let newPosts = await getProductos(perPage, offset);
          while (newPosts.length > 0) {
            allPosts = [...allPosts, ...newPosts];
            offset += perPage;
            newPosts = await getProductos(perPage, offset);
          }
          allPosts.sort(compareMedidas);
          setProductos(allPosts);
          setFilteredProductos(allPosts);
          console.log("Fin de chargement des productos:" , allPosts);
        }
        fetchProductos();
    },[])

    function getStockAlmacen(ubicaciones)
    {
        if(Array.isArray(ubicaciones)&&ubicaciones.length>0)
        {
            let totalStockAlmacen = 0;
            ubicaciones.map(ubicacion => {
                totalStockAlmacen += Number(ubicacion.cantidad);
            });
            return totalStockAlmacen;
        }
    }

    function filterResults(){
        let inputValue = document.querySelector("input.filter").value.toLowerCase();
        let tempFiltered=[];
        if(inputValue!=""){
            productos.map(producto => {
                if(producto.title.rendered.toLowerCase().includes(inputValue) || producto.acf.medidas.toLowerCase().includes(inputValue) || producto.acf.elemento.toLowerCase().includes(inputValue))
                {
                    tempFiltered.push(producto);
                }
            })
            console.log("Filtered : ",tempFiltered);
            tempFiltered.length==0 ? setFilteredProductos(false) : setFilteredProductos(tempFiltered);
        }
        else{
            setFilteredProductos(productos);
        }
    }

    const [productToDelete, setProductToDelete] = useState(false);
    function deleteProduct(){

        function deleteProductFromDB(){
            axios.delete(apiRoot+"/wp/v2/productos/" + productToDelete.id)
            .then(function (response) {
                console.log("Deleted producto response:",response);
                //on met à jour productos et filteredProductos :
                let indexProductosToDelete = productos.findIndex(element => element.id == productToDelete.id);
                productos.splice(productos.findIndex(element => element.id == productToDelete.id),1);
                setProductos(productos);
                filterResults();
            
                //on remet le useState à 0
                setProductToDelete(false);
            })
            .catch(function (error) {
                handleAxiosError(error);
                //on remet le useState à 0
                setProductToDelete(false);
            });
        }
        
        //TODO : supprimer les posts des movimientos qui lui sont associé avec ça: ET DECOMMENTER CE QU'IL Y A AU DESSUS
        if(productToDelete.acf.movimientos)
        {
            let movimientosToDelete = productToDelete.acf.movimientos;
              
              axios.all(movimientosToDelete.map((movimiento) => axios.delete(apiRoot+"/wp/v2/movimientos/"+movimiento.id))).then(
                ()=>{
                    console.log("All movimientos from product deleted");
                    deleteProductFromDB();
                }
              );
        }
        else{
            console.log("No movimientos from product to delete");
            deleteProductFromDB();
        }
    }

    const [deletePopup, setDeletePopup] = useState(false);
    function showDeletePopup(producto){
        setProductToDelete(producto);
        setDeletePopup(true);
    }

    const [originalValueBeforeInputRefresh, setOriginalValueBeforeInputRefresh] = useState(false);
    
    function updateInput(e, producto, originalValue){ //sert à modifier la valeur du produit dans le composant (pour pouvoir taper des changements dans le input)
        const elementValue = e.target.value;
        let productoTemp = producto;
        const elementClass = e.target.classList.value; //la classe sera l'élément à changer dans le tableau temporaire
        let originalValueChanged=false;

        if(!originalValueBeforeInputRefresh)
        {
            setOriginalValueBeforeInputRefresh(originalValue);
        }

        if(originalValue!=elementValue){
            originalValueChanged=true;
            // eslint-disable-next-line default-case
            switch (elementClass) {
                case "medidas":
                    productoTemp.acf.medidas=elementValue;
                    break;
            
                case "elemento":
                    productoTemp.acf.elemento=elementValue;
                    break;

                case "referencia"://title
                    productoTemp.title.rendered=elementValue;
                    break;
                case "stockAlmacen":
                    productoTemp.acf.stock_almacen=elementValue;
                    break;
                case "stockGeneral":
                    productoTemp.acf.stock=elementValue;
                    break;
            }
        }
        if(originalValueChanged) //si la valeur a été modifiée dans le formulaire on raffraichit le productos puis le filter
        {
            const indexProductoInProductosArr = productos.findIndex(element => element.id==producto.id);
            productos[indexProductoInProductosArr]=productoTemp;
            setProductos([...productos]);
            filterResults();
        }
    }

    function updateProductoInDB(e, producto){ //sert à faire que les changements soient persistant en DB
        const newValue = e.target.value;
        const elementClass = e.target.classList.value; //la classe sera l'élément à changer
        let objectToPut;

        if(newValue!=="" && originalValueBeforeInputRefresh && originalValueBeforeInputRefresh!==newValue){
            // eslint-disable-next-line default-case
            switch (elementClass) {
                case "medidas":
                    objectToPut={
                        fields:{medidas:newValue}
                    }
                    break;
            
                case "elemento":
                    objectToPut={
                        fields:{elemento:newValue}
                    }
                    break;
    
                case "referencia"://title
                    objectToPut={
                        title:newValue
                    }
                    break;
                case "stockAlmacen":
                    objectToPut={
                        fields:{stock_almacen:newValue}
                    }
                    break;
                case "stockGeneral":
                    objectToPut={
                        fields:{stock:newValue}
                    }
                    break;
                    
            }
            axios.put(apiRoot+"/wp/v2/productos/"+producto.id,objectToPut)
            .then(function (response) {
                console.log("Object To put:",objectToPut);
                console.log("Update producto response:",response);
            })
            .catch(function (error) {
                handleAxiosError(error);
            });
        }
        setOriginalValueBeforeInputRefresh(false); //on remet cette variable à zéro
    }

    const [idProductoPopupMovimiento, setIdProductoPopupMovimiento] = useState(false);

    const [incidenciasPopup, setIncidenciasPopup] = useState(false);
    function showIncidenciasPopup(){
        setIncidenciasPopup(true);
    }

    const [impresionesPopup, setImpresionesPopup] = useState(false);
    function showImpresionesPopup(){
        setImpresionesPopup(true);
    }


    return (
        <div>
            <Header>Stock</Header>
            {
                productos&&naves ?
                (
                    <div id="despacho">
                        <input className="filter" type="text" onChange={filterResults} onBlur={filterResults} placeholder="🔎 Buscar un producto"></input>
                        {
                            incidencias&&
                                <p style={{position:"absolute",right:20,top:40,cursor:"pointer"}} onClick={showIncidenciasPopup}><b>📛 Incidencias</b></p>
                        }
                        {
                            incidenciasPopup&&
                                <IncidenciasPopup setIncidenciasPopup={setIncidenciasPopup} incidencias={incidencias} setIncidencias={setIncidencias} />
                        }
                        {
                            impresiones&&
                                <p style={{position:"absolute",right:20,top:70,cursor:"pointer"}} onClick={showImpresionesPopup}><b>🖨️ Impresiones pendientes</b></p>
                        }
                        {
                            impresionesPopup&&
                                <ImpresionesPopup setImpresionesPopup={setImpresionesPopup} impresiones={impresiones} setImpresiones={setImpresiones} productos={productos} />
                        }
                        <table id="stockDespacho">
                            <thead>
                                <tr>
                                    <th>Medidas</th>
                                    <th>Elemento</th>
                                    <th>Referencia</th>
                                    <th>Ubicaciones</th>
                                    <th>Stock almacen</th>
                                    <th>Stock general</th>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    filteredProductos ?
                                        filteredProductos.map((producto) => {
                                            return (
                                                <tr key={producto.id} id={producto.id}>
                                                    <td title="Editar texto"><input type="text" className="medidas" value={producto.acf.medidas} onChange={e => updateInput(e, producto, producto.acf.medidas)} onBlur={e => updateProductoInDB(e, producto)}></input></td>
                                                    <td title="Editar texto"><input type="number" className="elemento" value={producto.acf.elemento} onChange={e => updateInput(e, producto, producto.acf.elemento)} onBlur={e => updateProductoInDB(e,producto)}></input></td>
                                                    <td title="Editar texto"><input type="text" className="referencia" value={producto.title.rendered} onChange={e => updateInput(e, producto, producto.title.rendered)} onBlur={e => updateProductoInDB(e,producto)}></input></td>
                                                    <td className="ubicaciones">{producto.acf.ubicaciones ? <Ubicaciones ubicaciones={producto.acf.ubicaciones} producto={producto.acf.medidas+" "+producto.title.rendered+" "+producto.acf.elemento} naves={naves} /> : "Ninguna"}</td>
                                                    <td className="stockAlmacen">{getStockAlmacen(producto.acf.ubicaciones)}</td>
                                                    <td><input type="number" className="stockGeneral" value={producto.acf.stock?producto.acf.stock:""} onChange={e => updateInput(e, producto, producto.acf.stock)} onBlur={e => updateProductoInDB(e,producto)}></input></td>
                                                    <td className="history" onClick={() => setIdProductoPopupMovimiento(producto.id)} title="Mostrar movimientos">🕓</td>
                                                    <td className="deleteProduct" onClick={() => showDeletePopup(producto)} title="Eliminar producto">❌</td>
                                                </tr>
                                            )
                                        }) :
                                    <tr><td>Ningun producto encontrado</td></tr>
                                }
                            </tbody>
                        </table>
                        {
                            deletePopup &&
                                <Confirmation action={deleteProduct} showConfirmation={deletePopup} setShowConfirmation={setDeletePopup}>
                                    Suprimir {productToDelete.title.rendered} ?
                                </Confirmation>
                        }
                        {
                            idProductoPopupMovimiento &&
                                <Movimientos idProductoPopupMovimiento={idProductoPopupMovimiento} setIdProductoPopupMovimiento={setIdProductoPopupMovimiento} />
                        }
                    </div>
                )
                :
                <Loader id="mainLoader" />
            }
            
        </div>
    )
}

export default Stock