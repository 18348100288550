import Header from "../../components/Header/Header";
import Nav from "../../components/Header/Nav/Nav";

function Almacen() {
    return (
      <div id="almacen">
          <Header>Almacen</Header>
          <Nav />
      </div>
    );
  }
  
  export default Almacen;