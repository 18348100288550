import Header from "../../components/Header/Header";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { apiRoot, handleAxiosError, compare } from "../../utils/utils";
import Ubicaciones from "../../components/despacho/Stock/Ubicaciones";
import "./Localizar.css";
import {ReactComponent as Loader} from "../../assets/images/loader.svg";

const compareByMedidas = (a,b) => {
  if ( a.acf.medidas < b.acf.medidas ){
      return -1;
  }
  if ( a.acf.medidas > b.acf.medidas ){
      return 1;
  }
  return 0;
}

function Localizar() {

  //chargement initial des produits au lancement du component :
  const [productos, setProductos] = useState(false);
  const [naves, setNaves] = useState(false);
  const navigate = useNavigate();

  useEffect(()=>{

    //on récupère les infos des naves
    async function getNaves(perPage, offset) {
      const response = await axios.get(apiRoot+"/wp/v2/naves?_fields=title,id&per_page="+perPage+"&offset="+offset);
      console.log("Chargement naves response:" , response.data);
      return response.data;
    }

    async function fetchNaves() {
        const perPage = 100;
        let offset = 0;
        let allPosts = [];
        let newPosts = await getNaves(perPage, offset);
        while (newPosts.length > 0) {
          allPosts = [...allPosts, ...newPosts];
          offset += perPage;
          newPosts = await getNaves(perPage, offset);
        }
        setNaves(allPosts);
        console.log("Fin de chargement des naves:" , allPosts);
      }
      fetchNaves();

      //récupération des produits
      async function getProductos(perPage, offset) {
          const response = await axios.get(apiRoot+"/wp/v2/productos?_fields=title,id,acf.medidas,acf.elemento,acf.ubicaciones&per_page="+perPage+"&offset="+offset);
          console.log("Chargement Productos response:" , response.data);
          return response.data;
      }
      async function fetchProductos() {
        const perPage = 100;
        let offset = 0;
        let allPosts = [];
        let newPosts = await getProductos(perPage, offset);
        while (newPosts.length > 0) {
          allPosts = [...allPosts, ...newPosts];
          offset += perPage;
          newPosts = await getProductos(perPage, offset);
        }
        setProductos(allPosts);
        setFilteredProductos(false);
        console.log("Fin de chargement des productos:" , allPosts);
      }
      fetchProductos();
  },[])

  //tri des résultats de recherche produit via le premier input
  const [filteredProductos, setFilteredProductos] = useState(false);
  function filterResults(stringToSearch){
    let tempFiltered=[];
    productos.map(producto => {
      if(stringToSearch!=""){
        if(producto.title.rendered.toLowerCase().includes(stringToSearch) || producto.acf.medidas.toLowerCase().includes(stringToSearch) || producto.acf.elemento.toLowerCase().includes(stringToSearch))
        {
            tempFiltered.push(producto);
        }
      }
    });
    if(tempFiltered.length==0){
      setFilteredProductos(false)
    }
    else{
      tempFiltered.sort(compareByMedidas);
      setFilteredProductos(tempFiltered);
    }
  }

  const [searchProductInputValue, setSearchProductInputValue] = useState("");
  function searchProduct(e){
    if(e.target.value!="")
    {
      filterResults(e.target.value);
      setSearchProductInputValue(e.target.value);
    }
    else{
      setSearchProductInputValue("");
      setFilteredProductos(false);
    }
  }

  function returnProductFromId(id){
    return productos.find(producto => producto.id == id);
  }

  const [selectedProduct, setSelectedProduct] = useState(false);
  function showLocalisationsFromProduct(e){
    const selectedProductId = e.target.value;
    setSelectedProduct(returnProductFromId(selectedProductId));
  }

  useEffect(()=>{
    //pour déclencher la recherche de produit si un produit est trouvé, sans que ça soit au changement du select, juste la première fois qu'on trouve un produit
    if(filteredProductos){
      const selectedProductId = document.querySelector("#resultList").value;
      setSelectedProduct(returnProductFromId(selectedProductId));
    }
  },[filteredProductos])

  function sendToEntradaOSalida(){
    alert("envoyer a entrada ou salida");
  }

    return (
      <div id="almacen" className="centered">
          <Header>Localizar</Header>
          <h1>Localizar palets</h1>

          {
            productos&&naves ?
            (
              <form onSubmit={(e) => sendToEntradaOSalida(e)}>
                <h4 className="label">Buscar y selecionnar  un producto:</h4>
                <input id="searchProduct" type="text" placeholder="Medidas, nombre, elemento..." value={searchProductInputValue} onChange={e => searchProduct(e)} onBlur={e => searchProduct(e)}></input>
                <br />
                {
                    filteredProductos &&
                    <>
                      <select id="resultList" onChange={e => showLocalisationsFromProduct(e)} onBlur={e => showLocalisationsFromProduct(e)}>
                        {
                          filteredProductos.map((producto) => {
                              return (
                                  <option key={producto.id} value={producto.id}>
                                    {producto.acf.medidas} - {producto.title.rendered} - {producto.acf.elemento}
                                  </option>
                              )
                          })
                        }
                      </select>
                      {
                        selectedProduct && selectedProduct.acf.ubicaciones ?
                        <Ubicaciones ubicaciones={selectedProduct.acf.ubicaciones} naves={naves} isHtmlBlock={true} /> :
                        <p>Ninguna ubicación</p>

                      }
                      
                    </>
                  }
          </form>
          ):
                <Loader id="mainLoader" />
              }
      </div>
    );
  }
  
  export default Localizar;