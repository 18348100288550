import Header from "../components/Header/Header"
import { useEffect, useState } from "react"
import axios from "axios";
import { apiRoot } from "../utils/utils";
import { compare } from "../utils/utils";
import { handleAxiosError } from "../utils/utils";
import moment from "moment/moment";
import "./Estadisticas.css";
import { ReactComponent as Loader } from "../assets/images/loader.svg";

const compareMovimientosDate = (a,b) => {
  if ( a.date < b.date ){
      return 1;
  }
  if ( a.date > b.date ){
      return -1;
  }
  return 0;
}

function Estadisticas(){
  
  let productos=[];
  let salidas=[];

  const [filteredSalidasYearsMonthsDays, setFilteredSalidasYearsMonthsDays] = useState(false);
  const [allLoaded, setAllLoaded] = useState(false);
  
  useEffect(()=>{
      //on récupère les infos les movimientos
      async function getMovimientos(perPage, offset) {
          const response = await axios.get(apiRoot+"/wp/v2/movimientos?_fields=acf.movimientos&per_page="+perPage+"&offset="+offset);
          console.log("Chargement post movimiento response:" , response.data);
          return response.data;
      }

      async function fetchMovimientos() {
        const perPage = 100;
        let offset = 0;
        let allPosts = [];
        let newPosts = await getMovimientos(perPage, offset);
        while (newPosts.length > 0) {
          allPosts = [...allPosts, ...newPosts];
          offset += perPage;
          newPosts = await getMovimientos(perPage, offset);
        }
        console.log("Fin de chargement des movimientos:" , allPosts);
        let postsMovimientos=allPosts;
        //on va récupérer tous les mouvements "salida"
        postsMovimientos.map((postMovimiento)=>{
          if(postMovimiento.acf.movimientos){
              postMovimiento.acf.movimientos.map((movimiento) => {
                if(movimiento.tipo=="salida")
                {
                  salidas.push(movimiento)
                }
              });
            }
          })
          salidas.sort(compareMovimientosDate);
          console.log("Toutes les salidas :");
          console.log(salidas);
          setAllLoaded(true);
          if(salidas.length>0)
          {
            //on regarde la premiere et la dernière date
            let lastDate=moment(Number(salidas[0].date));
            let firstDate=moment(Number(salidas[salidas.length-1].date));
            //on regarde le nombre d'années d'écarts entre les deux
            let differenceAnneesEntreLesDeuxDatesOpposees=Number(lastDate.format("YYYY")) - Number(firstDate.format("YYYY"));
            //dernière année
            let lastYear=Number(lastDate.format("YYYY"));
            //tableaux des années où on va stocker les salidas
            let salidasYearsMonthsDays=[];
            let i=0;

            function creationNouvelleEntreeTableauYear(salida,incrementI){
              let year = moment(Number(salida.date)).format("YYYY");
              let months = {totals:new Array(12)};
              let daysInMonths = new Array(12);
              for (let j=0;j<daysInMonths.length;j++)
              {
                daysInMonths[j]={totals:new Array(31)}
              }
              salidasYearsMonthsDays.push({year:year,salidas:[],months:months,daysInMonths:daysInMonths});
              if(incrementI)
              {
                i++;
              }            
              salidasYearsMonthsDays[i].salidas.push(salida);
            }

            if(differenceAnneesEntreLesDeuxDatesOpposees>0) // si supérieur à zéro les salidas concernent plusieurs années
            {
              salidas.map((salida)=>{ //plusieurs années :
                let year = moment(Number(salida.date)).format("YYYY");
                if(salidasYearsMonthsDays.length>i){ //si on a déjà qqch de créé dans le tableau d'années
                  if("year" in salidasYearsMonthsDays[i]) //et qu'on a déjà une donnée year dans l'entrée de tableau
                  {
                    //on vérifie que l'année corresponde
                    if(year == salidasYearsMonthsDays[i].year)
                    {
                      salidasYearsMonthsDays[i].salidas.push(salida); //si elle correspond on ajoute juste cette salida
                    } 
                    else{
                      creationNouvelleEntreeTableauYear(salida, true); //si elle ne correspond pas on créé une nouvelle entrée d'année dans le tableau principal et on augmente i++
                    }                 
                  }
                  else{
                    creationNouvelleEntreeTableauYear(salida, true); //si pas d'objet year dans le tableau on créé une nouvelle entrée d'année dans le tableau principal et on augmente i++
                  }
                }
                else{//pas d'entrée de tableau, c'est la première fois qu'on ouvre le tableau, on va en créer une entrée de tableau avec la valeur de l'année + la salida
                  creationNouvelleEntreeTableauYear(salida, false);
                }
              })
            }
            else if(differenceAnneesEntreLesDeuxDatesOpposees==0){ //une seule année : on enregistre l'année et toutes les données de salidas directement dans salidasYearsMonthsDays
              for (let j=0;j<salidas.length;j++){
                if(j==0)
                {
                  creationNouvelleEntreeTableauYear(salidas[0], false);
                }
                else{
                  salidasYearsMonthsDays[i].salidas.push(salidas[j]);
                }
              }
            }
            for(let i=0;i<salidasYearsMonthsDays.length;i++)
            {
              let totalSalidasYear = 0;
              salidasYearsMonthsDays[i].salidas.map((salida)=>{
                //on ajoute au total annuel
                totalSalidasYear+=Number(salida.cantidad);
                //addition au total du mois
                let currentSalidaMonth = Number(moment(Number(salida.date)).format("MM"));
                console.log(salidasYearsMonthsDays);
                if(salidasYearsMonthsDays[i].months.totals[currentSalidaMonth-1])
                {
                  salidasYearsMonthsDays[i].months.totals[currentSalidaMonth-1]+=Number(salida.cantidad);
                }
                else{
                  salidasYearsMonthsDays[i].months.totals[currentSalidaMonth-1]=Number(salida.cantidad);
                }
                //addition au total du jour
                let currentSalidaDay = Number(moment(Number(salida.date)).format("DD"));
                if(salidasYearsMonthsDays[i].daysInMonths[currentSalidaMonth-1].totals[currentSalidaDay-1]){
                  salidasYearsMonthsDays[i].daysInMonths[currentSalidaMonth-1].totals[currentSalidaDay-1]+=Number(salida.cantidad);
                }
                else{
                  salidasYearsMonthsDays[i].daysInMonths[currentSalidaMonth-1].totals[currentSalidaDay-1]=Number(salida.cantidad);
                }
              })
              salidasYearsMonthsDays[i].total=totalSalidasYear;
            }
            console.log("Résultats après tri :")
            console.log(salidasYearsMonthsDays);
            setFilteredSalidasYearsMonthsDays(salidasYearsMonthsDays);
            console.log(filteredSalidasYearsMonthsDays);
          }
          else{
            //pas de salidas, on signale qu'on ne peut pas générer les stats
            setFilteredSalidasYearsMonthsDays(false);
          }          
      }
      fetchMovimientos();
  },[])

  useEffect(()=>{
    document.querySelectorAll(".empty").forEach((el) => {
      el.closest("table").style.display="none";
    })
  },[filteredSalidasYearsMonthsDays])
    
  return(
    <div id="stats" className="centered">
      <Header>Estadisticas</Header>
      {
        filteredSalidasYearsMonthsDays?
        <>
          <div id="statsMainContent">
            {
              filteredSalidasYearsMonthsDays&&
              filteredSalidasYearsMonthsDays.map((year,i) => (
                <div className="dailyYearMainContainer" key={"year"+i}>
                  <h2>{year.year}</h2>
                  <div className="dailyYearContainer" key={"year"+i}>
                  {
                    year.daysInMonths.map((month, index) => (
                                  
                      <table className="quotidien" key={"daily"+year.year+index}>
                        <caption>{index+1}/{year.year}</caption>
                        <thead>
                          <tr>
                            <th>Dia</th>
                            <th>Total dia</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            month.totals.map((total, numeroJour) => (
                              <tr key={year.year+"month"+numeroJour}>
                                <td>{numeroJour+1}</td>
                                <td>{total ? total : 0}</td>
                              </tr>
                            ))
                          }
                          <tr className="final"><td><b>Total mes</b></td><td><b className={year.months.totals[index]?"total":"empty"}>{year.months.totals[index]}</b></td></tr>
                        </tbody>                
                      </table>
                    ))
                  }            
                  </div>
                </div>
              ))
            }
          </div>

          <div id="annuel">
            <h2>Salidas Anuales</h2>
            <div id="totalAnnuelMainContainer">
              <table className="totalAnnuel">
                <thead>
                  <tr>
                    <th>Año</th>
                    <th>Total salidas</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    filteredSalidasYearsMonthsDays&&
                    filteredSalidasYearsMonthsDays.map((year) => (
                      <tr key={"yr"+year.year}>
                        <td>{year.year}</td>
                        <td>{year.total}</td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </div>
          </div> 
        </>:
        allLoaded?<h2>Ninguna salida encontrada, no se pueden generar estadísticas.</h2>:<Loader />
      }
    </div>
  )
}

export default Estadisticas