import "./Entrada.css"
import Header from "../../components/Header/Header";
import axios from "axios";
import { apiRoot, handleAxiosError, compare } from "../../utils/utils";
import { useState, useEffect } from "react";
import Scan from "../../components/almacen/Scan/Scan";
import Confirmation from "../../utils/Confirmation";
import { v4 as uuidv4 } from 'uuid';
import Resto from "../../components/almacen/Resto/Resto";
import { useNavigate } from "react-router-dom";
import {ReactComponent as Loader} from "../../assets/images/loader.svg";

const compareByMedidas = (a,b) => {
  if ( a.acf.medidas < b.acf.medidas ){
      return -1;
  }
  if ( a.acf.medidas > b.acf.medidas ){
      return 1;
  }
  return 0;
}

let paletToEnter = {
  productoId:false,
  productoName:false,
  codigoBarra:false,
  cantidad:false,
  nave:false,
  naveName:false,
  calle:false,
  calleName:false
}

let impresion = false;

function Entrada() {

    //chargement initial des produits au lancement du component :
    const [productos, setProductos] = useState(false);
    const [naves, setNaves] = useState(false);
    const navigate = useNavigate();

    useEffect(()=>{

      //on récupère les infos des naves
      async function getNaves(perPage, offset) {
        const response = await axios.get(apiRoot+"/wp/v2/naves?_fields=title,id,acf.calles&per_page="+perPage+"&offset="+offset);
        console.log("Chargement naves response:" , response.data);
        return response.data;
      }

      async function fetchNaves() {
          const perPage = 100;
          let offset = 0;
          let allPosts = [];
          let newPosts = await getNaves(perPage, offset);
          while (newPosts.length > 0) {
            allPosts = [...allPosts, ...newPosts];
            offset += perPage;
            newPosts = await getNaves(perPage, offset);
          }
          setNaves(allPosts);
          console.log("Fin de chargement des naves:" , allPosts);
        }
        fetchNaves();

        //récupération des produits
        async function getProductos(perPage, offset) {
            const response = await axios.get(apiRoot+"/wp/v2/productos?_fields=title,id,acf.medidas,acf.elemento,acf.ubicaciones&per_page="+perPage+"&offset="+offset);
            console.log("Chargement Productos response:" , response.data);
            return response.data;
        }
        async function fetchProductos() {
          const perPage = 100;
          let offset = 0;
          let allPosts = [];
          let newPosts = await getProductos(perPage, offset);
          while (newPosts.length > 0) {
            allPosts = [...allPosts, ...newPosts];
            offset += perPage;
            newPosts = await getProductos(perPage, offset);
          }
          setProductos(allPosts);
          setFilteredProductos(false);
          console.log("Fin de chargement des productos:" , allPosts);
        }
        fetchProductos();
    },[])

    //tri des résultats de recherche produit via le premier input
    const [filteredProductos, setFilteredProductos] = useState(false);
    function filterResults(stringToSearch){
      let tempFiltered=[];
      productos.map(producto => {
        if(stringToSearch!=""){
          if(producto.title.rendered.toLowerCase().includes(stringToSearch) || producto.acf.medidas.toLowerCase().includes(stringToSearch) || producto.acf.elemento.toLowerCase().includes(stringToSearch))
          {
              tempFiltered.push(producto);
          }
        }
      });
      if(tempFiltered.length==0){
        setFilteredProductos(false)
      }
      else{
        tempFiltered.sort(compareByMedidas);
        setFilteredProductos(tempFiltered);
      }
    }
    const [searchProductInputValue, setSearchProductInputValue] = useState("");
    function searchProduct(e){
      if(e.target.value!="")
      {
        filterResults(e.target.value);
        setSearchProductInputValue(e.target.value);
      }
      else{
        setSearchProductInputValue("");
      }
    }

    const [scanResult, setScanResult] = useState("");
    const [codigoBarraExistante, setCodigoBarraExistante] = useState(false);
    function updateCodigoBarra(e){
      paletToEnter.codigoBarra = paletToEnter.codigoBarra?false:true; //pour l'objet global qui sera renvoyé à la fin
      if(e.target.value!=""){
        if(e.target.value=="no") //si pas de code barre, celui-ci est le code cheat
        {
          impresion=true;
          setScanResult(uuidv4()); //on change le value de l'input dans le useState pour un code barre aléatoire
          setCodigoBarraExistante(false);
        }
        else{
          setScanResult(e.target.value); //on change le value de l'input dans le useState
          setCodigoBarraExistante(false);
          productos.map(producto => { //on fait une recherche pour voir si ce code barre existe dans les produits
            if(producto.acf.ubicaciones && producto.acf.ubicaciones.length>0){
              producto.acf.ubicaciones.map(palet => { //recherche par code barre
                if(palet.codigo_barra.toLowerCase()==e.target.value.toLowerCase()){
                  console.log('CODE BARRE EXISTANT dans la base de données : '+palet.codigo_barra.toLowerCase()+", code barre renseigné : "+e.target.value.toLowerCase());
                  setCodigoBarraExistante(true);
                }
                else{
                  paletToEnter.codigoBarra = palet.codigo_barra;
                }
              })
            }
          })
        }
      }
      else{
        setScanResult("");
      }
    }

    const [cantidad, setCantidad] = useState(0);
    const [cantidadErr, setCantidadError] = useState(false);
    function updateCantidad(e){
      if(e.target.value>0){
        setCantidad(e.target.value); //on change le value de l'input dans le useState
        paletToEnter.cantidad=e.target.value;
      }
      else{
        setCantidad(0);
        paletToEnter.cantidad=0;
      }
    }

    const [inputNaveValue, setInputNaveValue] = useState("none");
    const [callesSelected, setCallesSelected] = useState(false);
    function updateNaveInput(e){
      if(e.target.value!="none"){
        setInputNaveValue(e.target.value); //on change le value de l'input dans le useState et on inscrit l'id de la nave
        let callesToUseArray;
        console.log("NAVES",naves);
        console.log("NAVES",naves[naves.findIndex(element => element.id == e.target.value)]);
        callesToUseArray = naves[naves.findIndex(element => element.id == e.target.value)].acf.calles; //calles à utiliser
        setCallesSelected(callesToUseArray);
      }
      else{
        setInputNaveValue("none");
        setCallesSelected(false);
      }
    }

    let [creationSucess, setCreationSuccess] = useState(false);
    const [formErrors, setFormErrors] = useState();
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [finalPaletToEnter, setFinalPaletToEnter] = useState(false);
    function checkPaletAndAskConfirmation(e){
      e.preventDefault();
      setFormErrors(false);
      let errors=[];
      checkProductInput(errors);
      checkCodigoBarra(errors);
      checkCantidad(errors);
      checkNave(errors);
      checkCalle(errors);
      setFinalPaletToEnter(paletToEnter);
      errors.length==0 ? setShowConfirmation(true) : setFormErrors(errors);
    }

    function checkProductInput(errors){
      if(filteredProductos && document.querySelector("#resultList").value!=""){
        paletToEnter.productoId = document.querySelector("#resultList").value;
      }
      else{
        if(!paletToEnter.productoId || paletToEnter.productoId==0)
        {
          errors.push("Ningun producto seleccionado");
          paletToEnter.productoId = false;
        }
      }
    }

    function checkCodigoBarra(errors){
      if(document.querySelector("#codigoBarra").value!="" && !codigoBarraExistante){
        paletToEnter.codigoBarra = document.querySelector("#codigoBarra").value;
      }
      else{
        if(paletToEnter.codigoBarra==0 || paletToEnter.codigoBarra=="")
        {
          errors.push("Comprobar el código de barras");
          paletToEnter.codigoBarra = false;
        }
        else if(codigoBarraExistante)
        {
          errors.push("El código de barras ya existe, no se puede hacer una entrada de un palet con el mismo código de barras porque ese palet ya esta en stock");
          paletToEnter.codigoBarra = false;
        }
      }
    }

    function checkCantidad(errors){
      let value = document.querySelector("#cantidad") ? document.querySelector("#cantidad").value : false;
      if(value>0){
        paletToEnter.cantidad = value;
      }
      else{
        if(paletToEnter.cantidad<=0 || paletToEnter.cantidad=="")
        {
          errors.push("Comprobar la cantidad");
          paletToEnter.cantidad = false;
        }
      }
    }

    function checkNave(errors){
      let value = document.querySelector("#nave") ? document.querySelector("#nave").value : false;
      if(value!="" && value!="none"){
        paletToEnter.nave = value;
      }
      else{
        if(paletToEnter.nave==0 || paletToEnter.nave=="" || paletToEnter.nave=="none")
        {
          errors.push("Comprobar la nave");
          paletToEnter.nave = false;
        }
      }
    }

    function checkCalle(errors){
      let value = document.querySelector("#calle") ? document.querySelector("#calle").value : false;
      if(value!="" && value!="none"){
        paletToEnter.calle = value;
      }
      else{
        if(paletToEnter.calle==0 || paletToEnter.calle=="" || paletToEnter.calle=="none")
        {
          errors.push("Comprobar la calle");
          paletToEnter.calle = false;
        }
      }
    }

    function setPaletInDb(){

      let newUbicaciones = {
        id_palet:uuidv4(),
        codigo_barra:finalPaletToEnter.codigoBarra,
        cantidad:finalPaletToEnter.cantidad,
        nave_id:finalPaletToEnter.nave,
        calle:finalPaletToEnter.calle,
        autor:localStorage.user?localStorage.user:"Desconocido"
      }

      console.log(newUbicaciones);

      async function postPalet() {
        try {
          const currentPalet = await axios.get(apiRoot+"/wp/v2/productos/"+finalPaletToEnter.productoId+"?_fields=acf.ubicaciones")
          .then(function (response) {
              console.log("Get des current palettes/ubicaciones:",response);
              return response;
          });

          async function postNewPalet(){
            if(Array.isArray(currentPalet.data.acf.ubicaciones)&&currentPalet.data.acf.ubicaciones.length>0){ //si une ou plusieurs palettes sont existantes
              currentPalet.data.acf.ubicaciones.push(newUbicaciones);
              newUbicaciones = currentPalet.data.acf.ubicaciones;
              console.log(newUbicaciones);
            }
            else{
              newUbicaciones=[newUbicaciones]; //si aucune palette sont existante
              console.log(newUbicaciones);
            }
            axios.put(apiRoot+"/wp/v2/productos/"+finalPaletToEnter.productoId,{ //fields = acf
              fields:{
                  ubicaciones:newUbicaciones
              },
              status:"publish"
              })
              .then(function (response) {                
                  console.log("Creation palet response:",response);
                  //reset des inputs
                  function resetInputs(){
                    setSearchProductInputValue("");
                    setScanResult("");
                    setCantidad(0);
                    paletToEnter.cantidad=0;
                    setInputNaveValue("none");
                    setCallesSelected(false);
                    document.getElementById("nave").value="none";
                  }
                  if(impresion)
                  {
                    axios.post(apiRoot+"/wp/v2/impresiones/",{ //fields = acf
                      fields:{
                          barCode:finalPaletToEnter.codigoBarra,
                          //producto:document.querySelector("#resultList").options[document.querySelector("#resultList").selectedIndex].value,
                          producto:document.querySelector("#resultList").value,
                          ubicacion:document.querySelector("#nave").options[document.querySelector("#nave").selectedIndex].text+", Calle "+document.querySelector("#calle").options[document.querySelector("#calle").selectedIndex].text
                      },
                      status:"publish"
                    })
                    .then(function (response) {                
                      console.log("Creation impresión response:",response);
                    });
                  }
                  resetInputs();
                  setCreationSuccess(true); //on affiche l'écran de succès
                  setTimeout(() => {
                      setCreationSuccess(false); //on masque l'écran de succès 1.5 sec plus tard
                      //on redirige
                      navigate("/");
                  }, 1500);
              });
          }
          const newPalet = await postNewPalet();
        } catch (error) {
          handleAxiosError(error);
            setFormErrors(error.message); 
        }
      }

      postPalet();
    }

    return (
      <div id="almacen" className="centered">
          <Header>Entrada</Header>
          <h1>Entrada de palet</h1>

          {
            productos&&naves ?
            (
              <form onSubmit={(e) => checkPaletAndAskConfirmation(e)}>
                <h4 className="label">Buscar y seleccionar  un producto:</h4>
                <input id="searchProduct" type="text" placeholder="Medidas, nombre, elemento..." value={searchProductInputValue} onChange={e => searchProduct(e)} onBlur={e => searchProduct(e)}></input>
                <br />
                
                  {
                    filteredProductos &&
                      <select id="resultList">
                        {
                          filteredProductos.map((producto) => {
                              return (
                                  <option key={producto.id} value={producto.id}>
                                    {producto.acf.medidas} - {producto.title.rendered} - {producto.acf.elemento}
                                  </option>
                              )
                          })
                        }
                      </select>
                  }
                
                <h4 className="label">Entrar / escanear codigo de barra:</h4>
                <input id="codigoBarra" title="Código desconocido ? -> no" value={scanResult} onChange={e => updateCodigoBarra(e)} onBlur={e => updateCodigoBarra(e)}></input>
                <Scan setScanResult={setScanResult} hideText={true} />
                {
                  codigoBarraExistante&&(<h3 className="error">El código de barras ya existe, no se puede hacer una entrada de un palet con el mismo código de barras porque ese palet ya esta en stock</h3>)
                }

                <h4 className="label">Cantidad de planchas:</h4>
                <input id="cantidad" type="number" min="0" value={cantidad} onChange={e => updateCantidad(e)} onBlur={e => updateCantidad(e)}></input>
                <Resto setCantidad={setCantidad} standalone={false}></Resto>

                <h4 className="label">Nave / Calle</h4>
                <select id="nave" onChange={e => updateNaveInput(e)} onBlur={e => updateNaveInput(e)}>
                  <option value="none">Seleccionar una Nave</option>
                  {
                    naves&&
                    naves.map((nave) => {
                      return (
                        <option key={nave.id} value={nave.id}>{nave.title.rendered}</option>
                      )
                    })
                  }
                </select>
                {
                  callesSelected ?(
                    <>
                    <br />
                    <select id="calle">
                      <option value="none">Seleccionar una Calle</option>
                      {
                        callesSelected.map((calle) => {
                          return (
                            <option key={calle.calle} value={calle.calle}>{calle.calle}</option>
                          )
                        })
                      }
                    </select>
                    </>
                  )
                  : null
                }
                <br />
                <button id="submitWholeForm" type="submit" onClick={checkPaletAndAskConfirmation}>Añadir palet</button>
              </form>
              ):
                <Loader id="mainLoader" />
              }
              {
                formErrors&&(
                  <>
                  <h3 className="error">Comprobar los errores siguientes:</h3>
                  <p className="error">
                    {
                      formErrors.map((err,i)=>
                        {
                          return(
                            <span key={"a"+i}>
                              {err}<br />
                            </span>
                          )
                        }
                      )
                    }
                  </p>
                  </>
                )
              }
              <Confirmation action={setPaletInDb} showConfirmation={showConfirmation} setShowConfirmation={setShowConfirmation}>
                    Confirmar la entrada de:
                    <>
                      <br /><br />
                      <span>Código de barras:<br /> <span>{finalPaletToEnter.codigoBarra}</span></span><br /><br />
                      <span>Producto:<br /> <span>{document.querySelector("#resultList")&&document.querySelector("#resultList").options[document.querySelector("#resultList").selectedIndex].text}</span></span><br /><br />
                      <span>Cantidad de planchas:<br /> <span>{finalPaletToEnter.cantidad}</span></span><br /><br />
                      <span>Ubicación:<br /> <span>{document.querySelector("#nave")&&document.querySelector("#nave").options[document.querySelector("#nave").selectedIndex].text}, Calle {document.querySelector("#calle")&&document.querySelector("#calle").options[document.querySelector("#calle").selectedIndex].text}</span></span><br />
                    </>
              </Confirmation>
              {creationSucess && <div className="popup"><h2 style={{color: "green"}}>Entrada OK</h2></div>}
      </div>
    );
  }
  
  export default Entrada;