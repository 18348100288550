
import Header from "../components/Header/Header";
import Nav from "../components/Header/Nav/Nav";


function Despacho() {
    return (
      <div id="despacho">
          <Header>Despacho</Header>
          <Nav></Nav>          
      </div>
    );
  }
  
  export default Despacho;