import Header from "../../components/Header/Header";
import { useEffect, useState } from "react";
import axios from "axios";
import { apiRoot } from "../../utils/utils";
import { compare } from "../../utils/utils";
import { handleAxiosError } from "../../utils/utils";
import "./Incidencia.css";
import { v4 as uuidv4 } from 'uuid';
import Resto from "../../components/almacen/Resto/Resto";

const compareByMedidas = (a,b) => {
    if ( a.acf.medidas < b.acf.medidas ){
        return -1;
    }
    if ( a.acf.medidas > b.acf.medidas ){
        return 1;
    }
    return 0;
  }

function Incidencia() {

    //chargement initial des produits au lancement du component :
    const [productos, setProductos] = useState(false);

    useEffect(()=>{
    //récupération des produits
    async function getProductos(perPage, offset) {
        const response = await axios.get(apiRoot+"/wp/v2/productos?_fields=title,id,acf.medidas,acf.elemento,acf.ubicaciones&per_page="+perPage+"&offset="+offset);
        console.log("Chargement Productos response:" , response.data);
        return response.data;
    }
    async function fetchProductos() {
      const perPage = 100;
      let offset = 0;
      let allPosts = [];
      let newPosts = await getProductos(perPage, offset);
      while (newPosts.length > 0) {
        allPosts = [...allPosts, ...newPosts];
        offset += perPage;
        newPosts = await getProductos(perPage, offset);
      }
      setProductos(allPosts);
      setFilteredProductos(false);
      console.log("Fin de chargement des productos:" , allPosts);
    }
    fetchProductos();
    },[])

    //tri des résultats de recherche produit via le premier input
    const [filteredProductos, setFilteredProductos] = useState(false);
    function filterResults(stringToSearch){
    let tempFiltered=[];
    productos.map(producto => {
        if(stringToSearch!=""){
            if(producto.title.rendered.toLowerCase().includes(stringToSearch) || producto.acf.medidas.toLowerCase().includes(stringToSearch) || producto.acf.elemento.toLowerCase().includes(stringToSearch))
            {
                tempFiltered.push(producto);
            }
        }
    });
    if(tempFiltered.length==0){
        setFilteredProductos(false)
    }
    else{
        tempFiltered.sort(compareByMedidas);
        setFilteredProductos(tempFiltered);
    }
    }
    const [searchProductInputValue, setSearchProductInputValue] = useState("");
    function searchProduct(e){
        if(e.target.value!="")
        {
            filterResults(e.target.value);
            setSearchProductInputValue(e.target.value);
        }
        else{
            setSearchProductInputValue("");
        }
    }

    const [formErrors, setFormErrors] = useState(false);
    const [creationSuccess, setCreationSuccess] = useState(false);
    function checkAndSubmit(e){
        e.preventDefault();
        let errors=[];
        let productoName = false;
        let resultList = document.getElementById("resultList");
        let resultListText;
        if(resultList)
        {
            resultListText= resultList.options[resultList.selectedIndex].text;
        }        
        let cantidad = 0;
        let tipo = "0";
        let loteInput = "0";
        let idPaletInput = "0";
        //verif produit
        if(filteredProductos && document.querySelector("#resultList").value!=""){
            productoName = resultListText;
        }
        else{
            if(!productoName || productoName==0 || !resultListText)
            {
              errors.push("Ningun producto selecionnado");
              productoName = false;
            }
        }

        //verif quantité
        if(document.querySelector("#cantidad").value>0){
            cantidad = document.querySelector("#cantidad").value;
        }
        else{
            errors.push("Comprobar la cantidad");
            cantidad = 0;
        }
        
        //verif type d'incident
        if(document.querySelector("#tipo").value!="0"){
            tipo = document.querySelector("#tipo").value;
        }
        else{
            errors.push("Elegir un tipo de incidencia");
            tipo = "0";
        }

        //verif lote
        if(document.querySelector("#lote").value!=""){
            loteInput = document.querySelector("#lote").value;
        }
        else{
            errors.push("Falta el n° de lote");
            loteInput = "";
        }

        //verif ID Palet
        if(document.querySelector("#idPalet").value!=""){
            idPaletInput = document.querySelector("#idPalet").value;
        }
        else{
            errors.push("Falta el ID palet");
            idPaletInput = "";
        }

        //affiche les erreurs s'il y en a
        if(errors[0]){
            setFormErrors(errors);
        }
        else{ //sinon inscrit l'incident dans la DB
            setFormErrors(false);
            errors=[];
            axios.post(apiRoot+"/wp/v2/incidencias",{ //fields = acf
                title:uuidv4(),
                fields:{
                    producto_complete_name:productoName,
                    cantidad:cantidad,
                    tipo:tipo,
                    lote:loteInput,
                    idPalet:idPaletInput,
                    date:Date.now()
                },
                status:"publish"
            })
            .then(function (response) {
                console.log("Creation incidencia response:",response);
                document.getElementById('tipo').value="0";
                document.getElementById('cantidad').value="";
                document.getElementById('resultList').value="";
                document.getElementById('idPalet').value="";
                document.getElementById('lote').value="";
                setCreationSuccess(true); //on affiche l'écran de succès
                setTimeout(() => {
                    setCreationSuccess(false); //on masque l'écran de succès 2 sec plus tard
                    window.location.replace("/")
                }, 1500);
            })
            .catch(function (error) {
                handleAxiosError(error);
                setFormErrors(error.message);
            });
        }
    }

    const [cantidad, setCantidad] = useState(0);
    function updateCantidad(e){
      if(e.target.value>0){
        setCantidad(e.target.value); //on change le value de l'input dans le useState
      }
      else{
        setCantidad(0);
      }
    }

    const [lote, setLote] = useState("");
    function updateLote(e){
      if(e.target.value!=""){
        setLote(e.target.value); //on change le value de l'input dans le useState
      }
      else{
        setLote("");
      }
    }

    const [idPalet, setIdPalet] = useState("");
    function updateIdPalet(e){
      if(e.target.value!=""){
        setIdPalet(e.target.value); //on change le value de l'input dans le useState
      }
      else{
        setIdPalet("");
      }
    }

    return (
      <div id="almacen">
          <Header>Incidencia</Header>
          <div id="incidencia">
            <h1>Informar de una incidencia</h1>
            <form id="incidenciaForm" onSubmit={(e)=>{checkAndSubmit(e)}}>
            <h4 className="label">Buscar y selecionnar  un producto:</h4>
                <input id="searchProduct" type="text" placeholder="Medidas, nombre, elemento..." value={searchProductInputValue} onChange={e => searchProduct(e)} onBlur={e => searchProduct(e)}></input>
                <br />
                
                {
                    filteredProductos &&
                    <>
                        <select id="resultList">
                            {
                            filteredProductos.map((producto) => {
                                return (
                                    <option key={producto.id} value={producto.id}>
                                        {producto.acf.medidas} - {producto.title.rendered} - {producto.acf.elemento}
                                    </option>
                                )
                            })
                            }
                        </select>
                    <br />                    
                    </>
                }
                <h4 className="label">Cantidad afectada:</h4>
                <input type="number" id="cantidad" placeholder="Cantidad" value={cantidad} onChange={e => updateCantidad(e)} onBlur={e => updateCantidad(e)}/>
                <Resto setCantidad={setCantidad} standalone={false}></Resto>
                <br />
                <h4 className="label">Tipo de incidencia:</h4>
                <select id="tipo">
                    <option value="0">Tipo de incidencia:</option>
                    <option value="Papel despegado">Papel despegado</option>
                    <option value="Impresion defectuosa">Impresion defectuosa</option>
                    <option value="Rotas en palets">Rotas en palets</option>
                    <option value="Mojadas">Mojadas</option>
                </select>
                <br />
                <h4 className="label">N° lote :</h4>
                <input type="number" id="lote" placeholder="N° lote" value={lote} onChange={e => updateLote(e)} onBlur={e => updateLote(e)}/>
                <br />
                <h4 className="label">ID Palet:</h4>
                <input type="number" id="idPalet" placeholder="ID Palet" value={idPalet} onChange={e => updateIdPalet(e)} onBlur={e => updateIdPalet(e)}/>
                <br />
                {
                    formErrors&&
                    <h3 className="error">{formErrors.map((formError)=>{
                        return(
                            <>
                                <span>{formError}</span>
                                <br />
                            </>
                        )
                    })}</h3>
                }
                {
                    creationSuccess&&
                    <h3 style={{color: "green"}}>Incidencia enviada correctamente</h3>
                }
                <button id="submitIncidencia" className="button" type="submit">Enviar</button>
            </form>
          </div>
      </div>
    );
  }
  
  export default Incidencia;