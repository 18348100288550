import { Link } from 'react-router-dom'
import "./Nav.css"
import { ReactComponent as LocalizarIcon } from "../../../assets/images/loupe.svg"
import { ReactComponent as ScanIcon } from "../../../assets/images/scan.svg"
import { ReactComponent as EntradaIcon } from "../../../assets/images/entrer.svg"
import { ReactComponent as SalidaIcon } from "../../../assets/images/sortir.svg"
import { ReactComponent as IncidenciaIcon } from "../../../assets/images/incidencia.svg"
import { ReactComponent as RestoIcon } from "../../../assets/images/calculator.svg"
import { ReactComponent as ExitIcon } from "../../../assets/images/exit.svg"

function Nav() {

    var role = localStorage.getItem("role");
    var showDespacho = false;
    if(role==="admin" || role==="despacho"){
        showDespacho=true;
    }

    return (
        <nav id="mainMenu" className={showDespacho?"despacho":" "}>
            
                {
                    showDespacho?

                    <div className="contentContainer despacho">
                        <Link to="/despacho/stock">Stock</Link>
                        <Link to="/despacho/nuevo_producto">Nuevo producto</Link>
                        <Link to="/despacho/naves">Gestion de naves</Link>
                        <Link to="/despacho/estadisticas">Estadisticas</Link>
                        <Link to="/almacen/localizar"><LocalizarIcon /> Localizar</Link>
                        <Link to="/almacen/scan"><ScanIcon />Scan codigo</Link>
                        <Link to="/almacen/entrada"><EntradaIcon />Entrada</Link>
                        <Link to="/almacen/salida"><SalidaIcon />Salida</Link>
                        <Link to="/almacen/incidencia"><IncidenciaIcon />Incidencia</Link>
                        <Link to="/almacen/resto"><RestoIcon />Resto</Link>
                        <Link to="/logout"><ExitIcon />Desconexión</Link>
                    </div>

                    :
                
                    <div className="contentContainer almacen">
                        <Link to="/almacen/localizar"><LocalizarIcon /> Localizar</Link>
                        <Link to="/almacen/scan"><ScanIcon />Scan codigo</Link>
                        <Link to="/almacen/entrada"><EntradaIcon />Entrada</Link>
                        <Link to="/almacen/salida"><SalidaIcon />Salida</Link>
                        <Link to="/almacen/incidencia"><IncidenciaIcon />Incidencia</Link>
                        <Link to="/almacen/resto"><RestoIcon />Resto</Link>
                        <Link to="/logout"><ExitIcon />Desconexión</Link>
                    </div>

                }
        </nav>
    )
}

export default Nav