import "./IncidenciasPopup.css"
import axios from "axios";
import { apiRoot } from "../../../utils/utils";
import { handleAxiosError } from "../../../utils/utils";

function IncidenciasPopup({setIncidenciasPopup, incidencias, setIncidencias}){
    function closePopup(){
        setIncidenciasPopup(false);
    }
    function avoidPropagation(e){
        e.stopPropagation();
    }
    function deleteIncidencia(id)
    {
        let newIncidencias = incidencias;
        newIncidencias.splice(newIncidencias.findIndex(element => element.id == id),1);
        //on modifie dans la base de données
        axios.delete(apiRoot+"/wp/v2/incidencias/"+id)
        .then(function (response) {
            console.log("Deleted incidencias response:",response);
            if(incidencias.length>0)
            {
                setIncidencias(newIncidencias => [...newIncidencias]); //on modifie le state incidencias (le spread permet de "refresh le state" sinon il n'est pas réellement pris en considération tout de suite)
            }
            else
            {
                setIncidencias(false); //on modifie le state incidencias
                window.location.reload(true);
            }
            
        })
        .catch(function (error) {
            handleAxiosError(error);
        });
    }
    return (
        <div id="incidenciasPopupContainer" onClick={() => closePopup()}>
            <div id="incidenciasDetails">
                <h2 className="blue">Incidencias</h2>
                <table id="tableIncidencias" onClick={(e)=>{avoidPropagation(e)}}>
                    <thead>
                        <tr>
                            <th>Fecha</th>
                            <th>Producto</th>
                            <th>Tipo de incidencia</th>
                            <th>N° lote</th>
                            <th>ID Palet</th>
                            <th>Cantidad</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            incidencias&&
                            (
                                incidencias.map((incidencia)=>{
                                    return(
                                        <tr key={incidencia.id}>
                                            <td className="date">{incidencia.acf.date?new Date(Number(incidencia.acf.date)).toLocaleDateString() + " " + new Date(Number(incidencia.acf.date)).toLocaleTimeString():"?"}</td>
                                            <td className="producto">{incidencia.acf.producto_complete_name}</td>
                                            <td className="tipo">{incidencia.acf.tipo}</td>
                                            <td className="lote">{incidencia.acf.lote}</td>
                                            <td className="idPalet">{incidencia.acf.idPalet}</td>
                                            <td className="cantidad">{incidencia.acf.cantidad}</td>
                                            <td className="deleteMovimiento" onClick={()=>{deleteIncidencia(incidencia.id)}}>❌</td>
                                        </tr>
                                    )
                                })
                            )
                        }
                    </tbody>
                </table>
            </div>            
        </div>
    )
}
export default IncidenciasPopup;