import axios from "axios";
import { apiRoot } from "../../../utils/utils";
import { useState, useEffect } from "react";
import { handleAxiosError } from "../../../utils/utils";
import { ReactComponent as AddIcon } from "../../../assets/images/add_circle.svg"
import Confirmation from "../../../utils/Confirmation";
import { v4 as uuidv4 } from 'uuid'


const compareMovimientosDate = (a,b) => {
    if ( a.date < b.date ){
        return 1;
    }
    if ( a.date > b.date ){
        return -1;
    }
    return 0;
}

let refresh = false;
let movimientosIds = false;
let freeSpotMovimientoPostID=false;
let allMovimientosByMovimientoID = [];
const maxMovimientosInPostMovimiento = 50;
let movimientoToDelete;
let movimientosOriginales = false;

function Movimientos({idProductoPopupMovimiento, setIdProductoPopupMovimiento}){

    const [movimientos,setMovimientos] = useState(false);
    const [loading,setLoading] = useState(true);
    const [stockOriginal,setStockOriginal] = useState(true); //quantité générale dans le produit
    

    useEffect(()=>{
        //on récupère les infos des movimientos du produit
        axios(apiRoot+"/wp/v2/productos/"+idProductoPopupMovimiento+"?_fields=acf.movimientos,acf.stock")
        .then(function (response) {
            console.log("Chargement movimientos del producto reponse:" , response.data);
            //on va les trier par date et afficher les résultats
            response.data.acf.movimientos&&response.data.acf.movimientos.sort(compareMovimientosDate);
            if(response.data.acf.stock)
            {
                setStockOriginal(Number(response.data.acf.stock)); //quantité présente dans le stock général
            }
            else{
                setStockOriginal(0); //quantité présente dans le stock général
            }            

            if(response.data.acf.movimientos) //si ids de movimientos
            {
                console.log("Liste des ids des posts movimientos:",response.data.acf.movimientos);
                movimientosIds = response.data.acf.movimientos;
                //en asynchrone on va récupérer tous les movimientos en fonction des ids présent dans le tableau movimientosIds
                const getMovimientosById = async (idMovimiento) => {
                    const response = await axios.get(apiRoot+"/wp/v2/movimientos/"+idMovimiento+"?_fields=acf.movimientos,acf.isFull");
                    let temp = {
                        id:idMovimiento,
                        movimientos:response.data.acf.movimientos
                    }
                    if(!response.data.acf.isFull && response.data.acf.movimientos.length<maxMovimientosInPostMovimiento)
                    {
                        freeSpotMovimientoPostID = idMovimiento;
                        console.log("Free spot dans movimiento avec ID:",idMovimiento);
                    }
                    else if(response.data.acf.movimientos.length>=maxMovimientosInPostMovimiento)
                    {
                       axios.put(apiRoot+"/wp/v2/movimientos/"+idMovimiento,{fields:{isFull:true}}).then("Movimiento " +idMovimiento+" paramétré sur isFull");
                    }
                    allMovimientosByMovimientoID.push(temp);
                    return response.data;
                };
                
                const getAllMovimimentos = async () => {
                    const promises = movimientosIds.map((movimientoId) => getMovimientosById(movimientoId.id));
                    const allMovimimentosFromPosts = await Promise.all(promises);
                    return allMovimimentosFromPosts;
                };
                
                getAllMovimimentos().then((allMovimimentosFromPosts) => { //une fois qu'on a récupéré tous les posts movimientos on va les ouvrir et les ajouter au allmovimientos
                    console.log("Movimientos from each movimiento post:",allMovimimentosFromPosts);
                    let allMovimimentos = [];

                    allMovimimentosFromPosts.forEach((movimientoFromPost)=>{
                        if(movimientoFromPost.acf.movimientos && movimientoFromPost.acf.movimientos.length>0)
                        {
                            movimientoFromPost.acf.movimientos.forEach((movimientoToPush)=>{
                                allMovimimentos.push(movimientoToPush)
                            })
                        }                        
                    });
                    console.log("AllMovimientos",allMovimimentos);
                    let tempMovesOrderedByDates = [...allMovimimentos];
                    tempMovesOrderedByDates.sort(compareMovimientosDate);
                    setMovimientos(tempMovesOrderedByDates);
                    movimientosOriginales=tempMovesOrderedByDates;
                    setLoading(false);
                }).catch((error) => {
                    handleAxiosError(error);
                });
                console.log("allMovimientosByMovimientoID at loading",allMovimientosByMovimientoID);
            }
            else{ //si pas de ids movimientos
                movimientosIds=false;
                freeSpotMovimientoPostID=false;
                allMovimientosByMovimientoID = [];
                movimientoToDelete=false;
                setLoading(false);
            }
        })
        .catch(function (error) {
            handleAxiosError(error);
        });
    },[])

    const [addMovimientoForm,setAddMovimientoForm] = useState(false);
    function showAddMovimientoForm(e){
        e.stopPropagation();
        setAddMovimientoForm(true);
    }

    //création de mouvement :
    let [formError, setErrorForm] = useState(false);

    function checkForm(){
        
        //gestion des erreurs
        const allInputs = document.querySelectorAll("#movimientos form input");
        const selectField = document.querySelector("#movimientoTipo");
        let rejectCreation = false;
        allInputs.forEach(element => {
            if(element.value===""){
                rejectCreation=true;
                setErrorForm("Comprobad los campos del formulario, algo no esta bien");
            }
        })

        if(selectField.value=="none")
        {
            rejectCreation=true;
            setErrorForm("Seleccione un tipo de movimiento");
        }

        //creation du movimiento en bdd
        if(!rejectCreation){
            //stocke le tableau de movimientos avec la nouvelle valeur
            //let newMovimientos=movimientos;
            let newMovimientos;
            const movimientoDateObject = new Date(document.querySelector("#movimientoDate").value);
            const movimientoDateMs = movimientoDateObject.getTime();
            console.log("New Date :",movimientoDateObject);
            let isProductMovimientosIDNeedsToBeUpdated = false;

            async function checkIfNewMovimientoPostNeedsToBeCreated(){
                if(!freeSpotMovimientoPostID)
                {
                    const createNewMovimientoPost = async () => {
                        const responseCreationMovimiento = await axios.post(apiRoot+"/wp/v2/movimientos",{status:"publish"})
                        if(responseCreationMovimiento){
                            console.log("Creation nouveau post movimiento (soit tous pleins soit inexistants):",responseCreationMovimiento);
                            freeSpotMovimientoPostID=responseCreationMovimiento.data.id;
                            return responseCreationMovimiento.data.id;
                        }
                        else{
                            return false;
                        }
                    }
                    const newMovimientoId = await createNewMovimientoPost();
                    if(newMovimientoId)
                    {
                        if(movimientosIds)
                        {
                            movimientosIds.push({id:newMovimientoId})
                            console.log("Tableau des ID de movimientos du produit:",movimientosIds);
                        }
                        else{
                            movimientosIds=[{
                                id:newMovimientoId
                            }]
                        }
                        //maj du tableau de movimiento du produit
                        isProductMovimientosIDNeedsToBeUpdated=true;
                    }
                }
                else{
                    if(allMovimientosByMovimientoID.length>0 && allMovimientosByMovimientoID.find(arrayVal => Number(arrayVal.id)==Number(freeSpotMovimientoPostID)).movimientos)
                    {
                        newMovimientos = allMovimientosByMovimientoID.find(arrayVal => Number(arrayVal.id)==Number(freeSpotMovimientoPostID)).movimientos;
                    }                    
                    return null;
                }
            }
            console.log(movimientos);
            let tempMovimientosToShow;
            if(movimientos){
                tempMovimientosToShow = [...movimientos];    
            }
            else
            {
                tempMovimientosToShow = movimientos;
            }            
            checkIfNewMovimientoPostNeedsToBeCreated().then(function(){
                let newMovimientosObjectToPush = {
                    date:movimientoDateMs,
                    cantidad:document.querySelector("#movimientoCantidad").value,
                    tipo:document.querySelector("#movimientoTipo").value,
                    albaran:document.querySelector("#movimientoAlbaran").value,
                    agente:localStorage.user?localStorage.user:"?",
                    id_unico:uuidv4(),
                    id_parent_product:idProductoPopupMovimiento,
                    id_parent_movimiento:freeSpotMovimientoPostID
                }
                if(newMovimientos)
                {
                    newMovimientos.push(
                        newMovimientosObjectToPush
                    );
                }
                else{
                    newMovimientos=[
                        newMovimientosObjectToPush
                    ];
                }
                if(tempMovimientosToShow)
                {
                    tempMovimientosToShow.push(
                        newMovimientosObjectToPush
                    )
                }
                else{
                    tempMovimientosToShow=[newMovimientosObjectToPush]
                }
                //calcul de la quantité finale du stock général :
                const tipo = document.querySelector("#movimientoTipo").value;
                const cantidadForm = document.querySelector("#movimientoCantidad").value;
                let newCantidad = stockOriginal;
                
                if(tipo == "salida" || tipo=="rota")
                {
                    newCantidad = newCantidad-Number(cantidadForm);
                    setStockOriginal(oldCantidad => oldCantidad = newCantidad);
                }
                else if(tipo == "entrada" || tipo == "corrección")
                {
                    newCantidad = newCantidad+Number(cantidadForm);
                    setStockOriginal(oldCantidad => oldCantidad = newCantidad);
                }

                let objectToPut ={
                    fields:{
                        movimientos:newMovimientos?newMovimientos:tempMovimientosToShow
                    }
                };

                setErrorForm(false); //si jamais il était sur true

                //on modifie dans la base de données
                axios.put(apiRoot+"/wp/v2/movimientos/"+freeSpotMovimientoPostID,objectToPut)
                .then(function (response) {
                    console.log("Movimientos qu'on va remplacer par cet objet:",objectToPut);
                    console.log("Réponse de l'update du movimiento:",response);
                    let objectToUpdate = {
                        fields:{
                            stock:newCantidad
                        }
                    }
                    if(isProductMovimientosIDNeedsToBeUpdated)
                    {
                        objectToUpdate = {
                            fields:{
                                movimientos:movimientosIds,
                                stock:newCantidad
                            }
                        }
                    }                    
                    axios.put(apiRoot+"/wp/v2/productos/"+idProductoPopupMovimiento,objectToUpdate)
                    .then(function (response){
                        console.log("Update producto with new array movimientos ID response:",response);
                        //newMovimientos.sort(compareMovimientosDate);
                        tempMovimientosToShow.sort(compareMovimientosDate);
                        setMovimientos(tempMovimientosToShow); //on modifie la valeur de movimientos
                        console.log("originales avant d'y toucher",movimientosOriginales);
                        if(movimientosOriginales)
                        {
                            movimientosOriginales.push(newMovimientosObjectToPush); //... et de ceux d'origine
                        }
                        else{
                            movimientosOriginales=[newMovimientosObjectToPush]
                        }
                        setAddMovimientoForm(false); //on enlève le formulaire d'ajout de mouvement

                        if(freeSpotMovimientoPostID)
                        {
                            let temp = {
                                id:freeSpotMovimientoPostID,
                                movimientos:newMovimientos
                            }
                            if(allMovimientosByMovimientoID.findIndex(element => Number(element.id) == Number(freeSpotMovimientoPostID))!==-1)
                            {
                                allMovimientosByMovimientoID[allMovimientosByMovimientoID.findIndex(element => Number(element.id) == Number(temp.id))].movimientos=temp.movimientos;
                            }
                            else{
                                allMovimientosByMovimientoID.push(temp);
                            }
                        }
                        refresh=true; //on set le refresh à true, ça demande à la fermeture du popup de rafraichir la page pour rafraichir le stock almacen
                    })
                    .catch(function (error) {
                        handleAxiosError(error);
                        setErrorForm(error.message);
                    });
                })
                .catch(function (error) {
                    handleAxiosError(error);
                    setErrorForm(error.message);
                });
            });
        }
    }

    function deleteMovimiento(){
        let newMovimientos = [...movimientos];
        let newMovimientosOriginales = [...movimientosOriginales];
        let idMovimientoPostParent = Number(movimientoToDelete.id_parent_movimiento);
        let idProductoPostParent = Number(movimientoToDelete.id_parent_product);
        let newCantidad=stockOriginal;
        console.log("DEBUT SUPPRESSION, allMovimientosByMovimientosID",allMovimientosByMovimientoID);

        //calcul de la nouvelle quantité
        if(movimientoToDelete.tipo == "salida" || movimientoToDelete.tipo=="rota")
        {
            newCantidad = newCantidad+Number(movimientoToDelete.cantidad);
            setStockOriginal(oldCantidad => oldCantidad = newCantidad);
        }
        else if(movimientoToDelete.tipo == "entrada" || movimientoToDelete.tipo == "corrección")
        {
            newCantidad = newCantidad-Number(movimientoToDelete.cantidad);
            setStockOriginal(oldCantidad => oldCantidad = newCantidad);
        }

        if(allMovimientosByMovimientoID.find(elemento => Number(elemento.id)==idMovimientoPostParent).movimientos.length==1)//plus qu'un seul element dans le tableau (en local et en bdd) -> supprimer l'id du tableau du producto et supprimer le post movimiento entier
        {
            console.log("PLUS QU'UN ELEMENT DANS LE TABLEAU à supprimer");
            movimientosIds.splice(movimientosIds.findIndex(elemento => Number(elemento.id) == idMovimientoPostParent),1); //on supprime du tableau d'ids du producto en local
            //on supprime le movimiento avec cet id en bdd
            axios.delete(apiRoot+"/wp/v2/movimientos/"+idMovimientoPostParent).then((response)=>
                    {
                        console.log("Supression du post movimiento car plus qu'une entrée dedans:",response);
                        //suppression de toute l'entrée dans le allMovimientosByMovimientoID local
                        allMovimientosByMovimientoID.splice(allMovimientosByMovimientoID.findIndex(elemento => Number(elemento.id)==idMovimientoPostParent),1);
                        if(freeSpotMovimientoPostID==idMovimientoPostParent)
                        {
                            freeSpotMovimientoPostID=false;
                        }
                    });
            let newIdsArrayToUpdateInProductos = {
                fields:{
                    movimientos:movimientosIds,
                    stock:newCantidad
                }
            }
            newMovimientos.splice(newMovimientos.findIndex(elemento => elemento.id_unico == movimientoToDelete.id_unico),1); //on supprime localement
            newMovimientosOriginales.splice(newMovimientos.findIndex(elemento => elemento.id_unico == movimientoToDelete.id_unico),1); //on supprime localement
            if(newMovimientos.length==0) //si on a plus du tout de mouvements
            {
                setMovimientos(false);
            }
            else{
                setMovimientos([...newMovimientos]); //sinon on change dans le state les mouvements
            }
            if(newMovimientosOriginales.length==0) //si on a plus du tout de mouvements
            {
                movimientosOriginales = false;
            }
            else{
                movimientosOriginales =[...newMovimientosOriginales]; //sinon on change dans le state les mouvements originaux
            }
            console.log("newMovimientosOriginales",newMovimientosOriginales);
            console.log("newMovimientos",newMovimientos);
            axios.put(apiRoot+"/wp/v2/productos/"+idProductoPostParent,newIdsArrayToUpdateInProductos) //mise à jour du produit en bdd
            .then(function (response){
                console.log("Update producto with new array movimientos ID response:",response);
                refresh=true; //on set le refresh à true, ça demande à la fermeture du popup de rafraichir la page pour rafraichir le stock almacen
            })
            .catch(function (error) {
                handleAxiosError(error);
                setErrorForm(error.message);
            });
        }
        else{ //il restera d'autres éléments dans le tableau
            //on splice du parentmovimiento, on met à jour le parentmovimiento dans la bdd, et le setmovimientos & originales & allmovimientos dans les variables:
            console.log("PLUSIEURS ELEMENTS DANS LE TABLEAU à supprimer");
            console.log("Avant splice newMovimientos",[...newMovimientos]);
            newMovimientos.splice(newMovimientos.findIndex(elemento => elemento.id_unico == movimientoToDelete.id_unico),1);
            newMovimientosOriginales.splice(newMovimientosOriginales.findIndex(elemento => elemento.id_unico == movimientoToDelete.id_unico),1);
            console.log("newMovimientos",newMovimientos);
            console.log("newMovimientosOriginales",newMovimientosOriginales);
            allMovimientosByMovimientoID.find(elemento => Number(elemento.id)==idMovimientoPostParent).movimientos.splice(allMovimientosByMovimientoID[allMovimientosByMovimientoID.findIndex(elemento => Number(elemento.id)==idMovimientoPostParent)].movimientos.findIndex(elemento => elemento.id_unico==movimientoToDelete.id_unico),1);
            setMovimientos([...newMovimientos]);
            movimientosOriginales =[...newMovimientosOriginales];

            let newStockCantidad = {
                fields:{
                    stock:newCantidad
                }
            }

            let newMovimientosToPut = {
                fields:{
                    movimientos:allMovimientosByMovimientoID.find(elemento => Number(elemento.id)==idMovimientoPostParent).movimientos
                }
            }
            axios.put(apiRoot+"/wp/v2/productos/"+idProductoPostParent,newStockCantidad)
            .then(function (response){
                console.log("Update producto with new cantidad:",response);
            })
            .catch(function (error) {
                handleAxiosError(error);
                setErrorForm(error.message);
            });

            axios.put(apiRoot+"/wp/v2/movimientos/"+idMovimientoPostParent,newMovimientosToPut)
            .then(function (response){
                console.log("Update movimiento with new array movimientos response:",response);
                refresh=true; //on set le refresh à true, ça demande à la fermeture du popup de rafraichir la page pour rafraichir le stock almacen
            })
            .catch(function (error) {
                handleAxiosError(error);
                setErrorForm(error.message);
            });
        }
    }

    const [deletePopup, setDeletePopup] = useState(false);
    function showDeletePopup(movimiento){
        movimientoToDelete=movimiento;
        console.log(movimientoToDelete);
        setDeletePopup(true);
    }

    const [errorFiltreDate, setErrorFiltreDate] = useState(false);
    function filterMovimientosByDate()
    {
        let newMovimiento = [...movimientosOriginales];
        setMovimientos(newMovimiento);
        const triDate1 = Number(new Date(document.querySelector("#triDate1").value).getTime());
        const triDate2 = Number(new Date(document.querySelector("#triDate2").value).getTime());
        if(!isNaN(triDate1) && !isNaN(triDate2))
        {
            if(triDate1 < triDate2)
            {
                let movimientosFilteredByDate = [];
                errorFiltreDate && setErrorFiltreDate(false); //si une erreur était affichée on la masque
                newMovimiento.forEach(movimiento => {
                    if(Number(movimiento.date) >= triDate1 && Number(movimiento.date) <= triDate2)
                    {
                        movimientosFilteredByDate.push(movimiento);
                    }
                });
                movimientosFilteredByDate.sort(compareMovimientosDate);
                setMovimientos(movimientosFilteredByDate);
            }
            else{
                setErrorFiltreDate("La fecha de fin es anterior a la fecha de inicio");
                setMovimientos(newMovimiento);
            }
        }
        else{
            console.log("Uno de los campos de fecha esta vacio");
            setMovimientos(newMovimiento); //pour pouvoir réafficher les résultats d'origine quand on enlève le filtre
        }
    }

    function closeOrRefresh()
    {
        if(refresh)
        {
            window.location.reload(true);
        }
        else{
            setIdProductoPopupMovimiento(false);
        }
    }

    return(
        <div className="confirmationPopupContainer" onClick={() => closeOrRefresh()}>
            <div id="movimientos" onClick={(e) => e.stopPropagation()}>
                <h2>Movimientos</h2>
                {
                    movimientos&&
                    <>
                        <input type="datetime-local" id="triDate1" onBlur={filterMovimientosByDate}></input>
                        <input type="datetime-local" id="triDate2" onBlur={filterMovimientosByDate}></input>
                        {
                            errorFiltreDate&&
                            <p className="error">{errorFiltreDate}</p>
                        }
                    </>
                }
                {
                    !addMovimientoForm&&
                    <>
                        <br />
                        <p className="button" onClick={e => showAddMovimientoForm(e)}>Añadir movimiento</p>
                    </>
                }
                {
                    addMovimientoForm&&
                    <form>
                        <input type="datetime-local" id="movimientoDate" placeholder="Fecha"></input>
                        <input type="text" id="movimientoAlbaran" placeholder="Albaran"></input>
                        <select id="movimientoTipo">
                            <option value="none">Tipo de movimiento:</option>
                            <option value="salida">Salida</option>
                            <option value="entrada">Entrada</option>
                            <option value="corrección">Corrección</option>
                            <option value="rota">Rota</option>
                        </select>
                        <input type="number" id="movimientoCantidad" placeholder="Cantidad"></input>
                        <AddIcon type="submit" onClick={checkForm} />
                        {
                            formError && 
                            <h4 className="error">{formError}</h4>
                        }
                    </form>
                }
            {
                loading ?
                ((<h4>Cargando...</h4>))
                :
                    movimientos?
                        <div>
                            <table id="tableMovimientos">
                                <thead>
                                    <tr>
                                        <th>Fecha</th>
                                        <th>Albaran</th>
                                        <th>Tipo</th>
                                        <th>Cantidad</th>
                                        <th>Agente</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                {
                                    movimientos.map(movimiento => (
                                        <tr className="movimiento" key={movimiento.id_unico}>
                                            <td className="date">{movimiento.date?new Date(Number(movimiento.date)).toLocaleDateString() + " " + new Date(Number(movimiento.date)).toLocaleTimeString():"?"}</td>
                                            <td className="albaran">{movimiento.albaran?movimiento.albaran:"movimiento.albaran"}</td>
                                            <td className="tipo">{movimiento.tipo?movimiento.tipo:"?"}</td>
                                            <td  className="cantidad">{movimiento.cantidad?movimiento.cantidad:"?"}</td>
                                            <td  className="agente">{movimiento.agente?movimiento.agente:"?"}</td>
                                            <td className="deleteMovimiento" onClick={() => showDeletePopup(movimiento)} title="Eliminar movimiento">❌</td>
                                        </tr>
                                    ))
                                }
                                {
                                    deletePopup &&
                                        <Confirmation action={deleteMovimiento} showConfirmation={deletePopup} setShowConfirmation={setDeletePopup}>
                                            ¿Suprimir el movimiento?
                                        </Confirmation>
                                }
                                </tbody>
                            </table>
                        </div>
                    :
                    (
                        <h3>No se ha encontrado ningún movimiento</h3>
                    )
            }
            </div>
        </div>
    )
}

export default Movimientos;