import "./NuevoProducto.css"
import Header from "../../Header/Header"
import { ReactComponent as AddIcon } from "../../../assets/images/add_circle.svg"
import { v4 as uuidv4 } from 'uuid'
import { handleAxiosError } from "../../../utils/utils";
import axios from "axios";
import { apiRoot } from "../../../utils/utils";
import { useState } from "react";


function NuevoProducto() {
    let [formError, setErrorForm] = useState(false);
    let [creationSucess, setCreationSuccess] = useState(false);

    function checkForm(){
        const allInputs = document.querySelectorAll("input");
        let rejectCreation = false;
        allInputs.forEach(element => {
            if(element.value===""){
                rejectCreation=true;
                setErrorForm("Comprobad los campos del formulario, algo no esta bien");
            }
        })

        //creation du produit
        if(!rejectCreation){
            setErrorForm(false);
            axios.post(apiRoot+"/wp/v2/productos",{ //fields = acf
                title:document.getElementById('nombre').value,
                fields:{
                    medidas:document.getElementById('medidas').value,
                    elemento:document.getElementById('elemento').value,
                    id_unico:uuidv4()
                },
                status:"publish"
            })
            .then(function (response) {
                console.log("Creation producto response:",response);
                document.getElementById('medidas').value="";
                document.getElementById('elemento').value="";
                document.getElementById('nombre').value="";
                setCreationSuccess(true); //on affiche l'écran de succès
                setTimeout(() => {
                    setCreationSuccess(false); //on masque l'écran de succès 2 sec plus tard
                }, 1500);
            })
            .catch(function (error) {
                handleAxiosError(error);
                setErrorForm(error.message);
            });
        }
    }

    return (
        <div>
            <Header>Añadir producto</Header>
            <div className="flexContainer">
                <h2>Añadir producto</h2>
                <form onSubmit={checkForm}>
                    <input id="nombre" type="text" placeholder="Nombre/Referencia" />
                    <input id="medidas" type="text" placeholder="Medidas" />
                    <input id="elemento" type="number" placeholder="Elemento" />
                    <AddIcon type="submit" onClick={checkForm} />
                </form>
                {formError && <p style={{color: "red"}}>{formError}</p>}
                {creationSucess && <div className="popup"><p style={{color: "green"}}>Producto correctamente creado</p></div>}
            </div>
        </div>
    )
}

export default NuevoProducto