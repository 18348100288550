import QRCode from "react-qr-code";
import { useLocation } from "react-router-dom";

function ImpresionQR(){
    const location = useLocation();
    let qr = new URLSearchParams(location.search).get('qr');
    let producto = new URLSearchParams(location.search).get('producto');
    let ubicacion = new URLSearchParams(location.search).get('ubicacion');
    let cantidad = new URLSearchParams(location.search).get('cantidad');
    return (
        <div style={{display:"flex",justifyContent:"center",flexFlow:"column wrap",alignItems:"center",width:"100vw",height:"100vh"}}>
            <QRCode
            size={256}
            style={{ height: "auto", maxWidth: "200px", width: "100%" }}
            value={qr}
            viewBox={`0 0 256 256`}
            />
            <h3>{producto}</h3>
            <h3>{ubicacion}</h3>
            <h3>Cantidad : {cantidad}</h3>
            <p style={{textAlign:"center"}}>Código de barras :<br />{qr}</p>
            <p style={{cursor:"pointer"}} onClick={()=>window.print()}>🖨️</p>
        </div>
    )
}

export default ImpresionQR