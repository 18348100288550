import { useNavigate } from 'react-router-dom'
import Nav from "./Nav/Nav"
import "./Header.css"
import { ReactComponent as BurgerIcon} from "../../assets/images/burger.svg"
import { ReactComponent as BackIcon } from "../../assets/images/back.svg"
import { ReactComponent as CloseIcon } from "../../assets/images/closeWhite.svg"
import { useLocation } from 'react-router-dom';
import { useState } from 'react'

function Header(props) {
    const navigate = useNavigate(); //navigate(-1) -> idem que faire précédent dans le navigateur
    
    var location = useLocation(); //pour récupérer l'url actuelle

    const [isNavOpen, setIsNavOpen] = useState(false);

    function showHideNav(isNavOpen){
        isNavOpen ? setIsNavOpen(false) : setIsNavOpen(true);
    }

    return (
        <header>
            <div id="headerUtilContainer">
                <div>
                    {location.pathname === '/' ? null : !isNavOpen && <BackIcon id="backButton" className="click" onClick={() => navigate(-1)} />}                
                </div>
                <h3>{props.children}</h3>
                <div>
                    {location.pathname === '/' ? null : !isNavOpen && <BurgerIcon id="burgerButton" onClick={()=>{showHideNav(isNavOpen)}} className="click" />}
                    {isNavOpen && <CloseIcon id="burgerButton" style={{cursor:"pointer"}} onClick={() => showHideNav(isNavOpen)} />}
                </div>
            </div>
            {isNavOpen ? <Nav /> : null}            
        </header>
    )
}

export default Header