import "./Entrada.css"
import Header from "../../components/Header/Header";
import axios from "axios";
import { apiRoot, handleAxiosError, compare } from "../../utils/utils";
import { useState, useEffect } from "react";
import Scan from "../../components/almacen/Scan/Scan";
import { Link } from "react-router-dom";
import {ReactComponent as Loader} from "../../assets/images/loader.svg";

const compareByMedidas = (a,b) => {
    if ( a.acf.medidas < b.acf.medidas ){
        return -1;
    }
    if ( a.acf.medidas > b.acf.medidas ){
        return 1;
    }
    return 0;
  }

function ScanCodigo() {

    //chargement initial des produits au lancement du component :
    const [productos, setProductos] = useState(false);

    useEffect(()=>{

      //on récupère les infos des produits
      //récupération des produits
    async function getProductos(perPage, offset) {
      const response = await axios.get(apiRoot+"/wp/v2/productos?_fields=title,id,acf.medidas,acf.elemento,acf.ubicaciones&per_page="+perPage+"&offset="+offset);
      console.log("Chargement Productos response:" , response.data);
      return response.data;
    }
    async function fetchProductos() {
      const perPage = 100;
      let offset = 0;
      let allPosts = [];
      let newPosts = await getProductos(perPage, offset);
      while (newPosts.length > 0) {
        allPosts = [...allPosts, ...newPosts];
        offset += perPage;
        newPosts = await getProductos(perPage, offset);
      }
      setProductos(allPosts);
      setFilteredProductos(false);
      console.log("Fin de chargement des productos:" , allPosts);
    }
    fetchProductos();
    },[])

    //tri des résultats de recherche produit via le premier input
    const [filteredProductos, setFilteredProductos] = useState(false);
    function filterResults(stringToSearch){
      let tempFiltered=[];
      productos.map(producto => {
        if(stringToSearch!=""){
          if(producto.title.rendered.toLowerCase().includes(stringToSearch) || producto.acf.medidas.toLowerCase().includes(stringToSearch) || producto.acf.elemento.toLowerCase().includes(stringToSearch))
          {
              tempFiltered.push(producto);
          }
        }
      });
      if(tempFiltered.length==0){
        setFilteredProductos(false)
      }
      else{
        tempFiltered.sort(compareByMedidas);
        setFilteredProductos(tempFiltered);
      }
    }

    const [scanResult, setScanResult] = useState("");
    const [codigoBarraExistante, setCodigoBarraExistante] = useState(false);

    function updateCodigoBarra(e){
      if(e.target.value!=""){
        setScanResult(e.target.value); //on change le value de l'input dans le useState
        setCodigoBarraExistante(false);
        productos.map(producto => { //on fait une recherche pour voir si ce code barre existe dans les produits
          if(producto.acf.ubicaciones && producto.acf.ubicaciones.length>0){
            producto.acf.ubicaciones.map(palet => { //recherche par code barre
              if(palet.codigo_barra.toLowerCase()==e.target.value.toLowerCase()){
                console.log('CODE BARRE EXISTANT dans la base de données : '+palet.codigo_barra.toLowerCase()+", code barre renseigné : "+e.target.value.toLowerCase());
                console.log("oui");
                setCodigoBarraExistante(true);
                //ajouter un son
              }
            })
          }
        })
      }
      else{
        setScanResult("");
      }
    }

    return (
      <div id="almacen" className="centered">
          <Header>Scan código</Header>
          <h1>Scan código</h1>
     
          {
            productos ?
            (
              <>
                <h4 className="label">Entrar / escanear código de barras:</h4>
                <input id="codigoBarra" title="Código desconocido ? -> no" value={scanResult} onChange={e => updateCodigoBarra(e)} onBlur={e => updateCodigoBarra(e)}></input>
                <Scan setScanResult={setScanResult} hideText={true} />
                {
                  codigoBarraExistante?(<h3>El código de barras ya existe <Link style={{color:"red"}} to="/almacen/salida">hacer una salida</Link></h3>):
                  (<h3>El código de barras no existe <Link className="blue" to="/almacen/entrada">hacer una entrada</Link></h3>)
                }
              </>
              ):
              <Loader id="mainLoader" />
            }
          
      </div>
    );
  }
  
  export default ScanCodigo;