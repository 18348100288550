import { saveAuthToken, handleAxiosError, removeAuthToken } from "../utils/utils";
import { useState, useEffect} from "react";
import { useNavigate} from "react-router-dom";
import axios from "axios";
import { apiRoot } from "../utils/utils";

function Login() {

    const [role, setRole] = useState("");
    const [isWaiting, setWaiting] = useState(false);
    const [password, setPass] = useState("");
    const [user, setUser] = useState("");
    const [errMsg, setErrMsg] = useState("");
    let navigate = useNavigate();

    useEffect(function(){
      if(localStorage.getItem("token")&&localStorage.getItem("role")){
        setRole(localStorage.getItem("role"));
      }
    },[role]);

    function changeUserValue(e){
      setUser(e.target.value);
      localStorage.setItem("user",e.target.value);
    }

    function changePasswordValue(e){
      setPass(e.target.value);
    }

    //get role after connected
    function checkRole(){
      axios(apiRoot+"/wp/v2/users/me?context=edit")
      .then(function (response) {
          console.log("checkRole response:",response);
          const role  = response.data.roles[0];
          if(role){
              localStorage.setItem("role",role);
          }
          console.log("Fin de l'inscription de rôle");
          navigate("/", { replace: true});
          return "Auth complete";
      })
      .catch(function (error) {
          handleAxiosError(error);
      });
    }

    //request JWT
    function connect(username, password){
      axios.post(apiRoot+"/jwt-auth/v1/token/",{
          username: username,
          password: password
      })
      .then(function (response){
        saveAuthToken(response.data.token);
        checkRole();
      })
      .catch(function (error) {
          setErrMsg(handleAxiosError(error));
          setWaiting(false);
      });
    }

    function tryConnect(event){
      event.preventDefault();
      setWaiting(true);
      connect(user,password);
    }

    
    const queryParams = new URLSearchParams(window.location.search);
    var rejectedFor = queryParams.get('rejectedFor');
    var logout = queryParams.get('logout');
    console.log(logout);

    return (
      <div id="login" style={{display:"flex",justifyContent:"center",flexFlow:"column wrap",alignItems:"center",minWidth:"100vw",minHeight:"100vh",textAlign:"center"}}>
        <div>
          <h1 style={{marginBottom:"0",textAlign:"center",maxWidth:"320px"}} className="blue">{rejectedFor ? "Inicie sesión para acceder al contenido:" : logout ? "Desconexión:" : "Conexión:"}</h1>
            {
              !role ? 
              isWaiting ? <h2>Cargando...</h2> : 
              <form onSubmit={tryConnect}>
                <input style={{display:"block",margin:"10px auto"}} type="text" placeholder="Usuario" onChange={changeUserValue} value={user} />
                <input style={{display:"block",margin:"10px auto"}} type="password" placeholder="Contraseña" onChange={changePasswordValue} value={password} />
                <button style={{display:"block",margin:"auto",marginTop:"20px",padding:"12px",backgroundColor:"#03a9f4",outline:"none",color:"white",border:"none",borderRadius:"5px"}} type="submit">Connexión</button>
                {errMsg!==""&&<h3 dangerouslySetInnerHTML={{__html: errMsg}} />}
              </form>
              :
              role && <h3 id="logOutButton" onClick={function(){
                removeAuthToken();
                setRole(localStorage.getItem("role"));
              }}>Logout</h3>
            }
        </div>
      </div>
    );
  }
  
  export default Login;