import { removeAuthToken } from "../utils/utils";
import { useState } from "react";
import Header from "../components/Header/Header";

function Logout() {

  const [role, setRole] = useState("");
    return (
      <div>
        <Header>Desconexión</Header>
        <div id="login" style={{display:"flex",justifyContent:"center",flexFlow:"row wrap",alignItems:"center",width:"100%",height:"calc(100vh - 40px)",textAlign:"center",boxSizing:"border-box"}}>
              <h3 id="logOutButton" onClick={function(){
                  removeAuthToken();
                  setRole(localStorage.getItem("role"));
                }}>Cerrar la sesión</h3>
        </div>
      </div>
    );
  }
  
  export default Logout;