import {Navigate} from 'react-router-dom';
import { saveAuthToken } from './utils';
import {useEffect, useState } from 'react';
import axios from 'axios';
import { apiRoot } from './utils';
import { handleAxiosError } from './utils';
  
const ProtectedRoute = ({ children, role }) => {  

    //check JWT token
    const [token, setToken] = useState(localStorage.getItem("token"));
    const [localRole, setLocalRole] = useState(localStorage.getItem("role"));

    useEffect(() => {
      setToken(localStorage.getItem("token"));
      setLocalRole(localStorage.getItem("role"));
    });

    if (token) {
      function checkToken(){
        saveAuthToken(token);
        axios.post(apiRoot+"/jwt-auth/v1/token/validate")
        .then(function (response){
            if(localStorage.getItem("token")!==token){
                localStorage.removeItem("token");
                localStorage.setItem("token",token);
            }
            console.log("Token valide placé dans header axios & localStorage + response:");
            console.log(response);

            //verification du role... pas fou parcequ'on peut modifier le cookie du rôle à la main pour augmenter son rôle...
            //...mais ça évite de vérifier le rôle à chaque appel (économise les appels serveur, ça passe sur un serveur où il n'y a rien de risqué)
            if (role) {
              if(!role.includes(localRole)){
                let params = {rejectedFor: "role", role: localRole};
                let queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
                return <Navigate to={"/login?"+queryString} replace />;
              }
            }
        })
        .catch(function (error) {
            console.log("Erreur d'authentification du token: ");
            console.log(error);
            handleAxiosError(error);
            let params = {rejectedFor: "token"};
            let queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
            return <Navigate to={"/login?"+queryString} replace />;
        });
      }
      checkToken();
      return children;
    }
    else{
      return <Navigate to={"/login?rejectedFor=token"} replace />;
    }
};

export default ProtectedRoute;