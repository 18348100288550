import { ReactComponent as RestoIcon } from "../../../assets/images/calculator.svg"
import { ReactComponent as Close } from "../../../assets/images/close.svg"
import { useState } from 'react'
import "./Resto.css"

function Resto({setCantidad, standalone}){

    const [isRestoPopupOpen, setIsRestoPopupOpen] = useState(false);
    const [cantidadStandalone, setCantidadStandalone] = useState(0);

    function openResto(){
        setIsRestoPopupOpen(true);
    }

    function closeResto(){
        setIsRestoPopupOpen(false);
        if(setCantidad){
            setCantidad(0);
        }
    }

    const [errorTipoCarton, setErrorTipoCarton] = useState("");
    const [errorNumeroPies, setErrorNumeroPies] = useState("");
    const [errorDimension, setErrorDimension] = useState("");
    

    function submitResto()
    {
        let mainErr = false;
        setErrorTipoCarton("");
        setErrorNumeroPies("");
        setErrorDimension("");
        let tipoCarton = document.getElementById("tipoCarton").value;
        let numeroDePies = document.getElementById("numeroPies").value;
        let dimension = document.getElementById("dimension").value;
        if(tipoCarton == "none")
        {
            setErrorTipoCarton("Tipo de carton no selecionado");
            mainErr = true;
        }
        if(numeroDePies == "" || numeroDePies <=0)
        {
            setErrorNumeroPies("Numero de pies incorrecto");
            mainErr = true;
        }
        if(dimension == "" || dimension <=0)
        {
            setErrorDimension("Dimension incorrecta");
            mainErr = true;
        }
        if(!mainErr){
            let newCantidad;
            if(tipoCarton == "gordo")
            {
                newCantidad = dimension / 10 * 15 * numeroDePies;
                newCantidad = Math.round(newCantidad);
            }
            else if(tipoCarton == "fino")
            {
                newCantidad = dimension / 10 * 24 * numeroDePies;
                newCantidad = Math.round(newCantidad);
            }
            if(standalone)
            {
                setCantidadStandalone(newCantidad);
            }
            else{
                if(setCantidad){
                    setCantidad(newCantidad);
                }                
                setIsRestoPopupOpen(false);
            }
        }
    }

    function checkIfPopupOpened(){
        if(isRestoPopupOpen || standalone)
        {
            if(standalone)
            {
                return "active standalone"
            }
            else
            {
                return "active"
            }            
        }
        else{
            return "inactive";
        }
    }

    const renderResto = () => {
        if (standalone || isRestoPopupOpen) {
          return <>
                <div id="restoPopupContainer" className={checkIfPopupOpened()} onClick={closeResto}>
                    <div id="restoPopup" onClick={(e) => e.stopPropagation()}>
                        {!standalone&&<Close id="closeResto" onClick={closeResto} style={{zIndex:"1000"}}/>}                        
                        <h3>CALCULO DE RESTO</h3>
                        <h4 className="label">Tipo de carton :</h4>
                        <select id="tipoCarton" onChange={standalone&&submitResto} onBlur={standalone&&submitResto}>
                            <option value="none">Selecionnar un tipo de carton</option>
                            <option value="gordo">Gordo</option>
                            <option value="fino">Fino</option>
                        </select>
                        {errorTipoCarton === "" ? null : <h4 style={{color:"red"}}>{errorTipoCarton}</h4>}
                        <h4 className="label">Numero de pies :</h4>
                        <input type="number" id="numeroPies" onChange={standalone&&submitResto} onBlur={standalone&&submitResto}></input>
                        {errorNumeroPies === "" ? null : <h4 style={{color:"red"}}>{errorNumeroPies}</h4>}
                        <h4 className="label">Dimension en cm :</h4>
                        <input type="number" id="dimension" onChange={standalone&&submitResto} onBlur={standalone&&submitResto}></input>
                        {errorDimension === "" ? null : <h4 style={{color:"red"}}>{errorDimension}</h4>}
                        {
                            standalone==true?<h1>Resultado : {cantidadStandalone}</h1>:<p className="button" type="submit" onClick={submitResto}>Aplicar la cantidad</p>
                        }
                    </div>
                </div>
            </>
        }
      }

    return(
        <div id="resto">
            {!standalone&&<RestoIcon onClick={openResto} />}
            {renderResto()}
        </div>
    )
}

export default Resto;