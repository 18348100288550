import { Html5QrcodeScanner, Html5QrcodeScanType } from "html5-qrcode";
import { ReactComponent as ScanIcon } from "../../../assets/images/scan.svg"
import { ReactComponent as CloseWhite } from "../../../assets/images/closeWhite.svg"
import { useState } from 'react'
import "./Scan.css"
import bipSound from "../../../assets/bip.mp3";

var html5QrcodeScanner; //globale pour pouvoir la "détruire" / clear depuis le click sur le bouton close / libère l'usage de la caméra

function Scan({setScanResult, hideText}){

    const [isScanPopupOpen, setIsScanPopupOpen] = useState(false);

    function openScan(){
        setIsScanPopupOpen(true);
    }

    function closeScan(){
        setIsScanPopupOpen(false);
        if(html5QrcodeScanner){
            html5QrcodeScanner.clear();
        }        
    }

    function bip(){
        new Audio(bipSound).play();
    }

    const [scanErrorMsg, setScanErrorMsg] = useState("");
    function onScanSuccess(decodedText, decodedResult) {
        // Handle on success condition with the decoded text or result.
        console.log(`component Scan : Scan result: ${decodedText}`, decodedResult);
        console.log(`component Scan : decodedText: ${decodedText}`, decodedResult);
        console.log(`component Scan : decodedResult: ${decodedResult}`);
        setScanResult(decodedText); //on met "id:" devant pour que la recherche cible directement l'id d'une palette
        setScanErrorMsg("");
        closeScan();
        document.querySelector("#codigoBarra").focus();
        document.querySelector("#codigoBarra").value = decodedText;
        document.querySelector("#codigoBarra").blur();
        //lecture son bip
        bip();
    }

    function onScanError(errorMessage) {
        setScanErrorMsg(errorMessage);
    }

    function changeScannerUI(){
        document.getElementById("scannerPopup__camera_permission_button").innerHTML = "Autorizar uso de la cámara";
    }

    function startScan(){

        openScan();        
        
        html5QrcodeScanner = new Html5QrcodeScanner("scannerPopup", { fps: 10, qrbox: 250, supportedScanTypes: [Html5QrcodeScanType.SCAN_TYPE_CAMERA],  });    
        
        html5QrcodeScanner.render(onScanSuccess, onScanError);

        changeScannerUI();
    }

    return(
        <div id="scanner">
            <ScanIcon onClick={startScan} />
            {
                !hideText&&
                <h4>SCAN CODIGO</h4>
            }
            <div id="scannerPopupContainer" className={isScanPopupOpen ? "active" : "inactive"}>
                <CloseWhite id="closeScan" onClick={closeScan} style={{zIndex:"1000"}}/>
                {scanErrorMsg === "" ? null : <h6 style={{color:"red",position:"absolute",zIndex:"1"}}>Debug = {scanErrorMsg}</h6>}
                <div id="scannerPopup"></div>
            </div>
        </div>
    )
}

export default Scan;