import Header from "../../components/Header/Header";
import Resto from "../../components/almacen/Resto/Resto";

function Restopage() {
    return (
      <div id="almacen">
          <Header>Resto</Header>
          <Resto setCantidad={false} standalone={true}></Resto>
      </div>
    );
  }
  
  export default Restopage;