import {Route,Routes} from "react-router-dom";
import './App.css'
import Almacen from './Pages/almacen/Almacen'
import Localizar from './Pages/almacen/Localizar'
import Entrada from "./Pages/almacen/Entrada";
import Salida from "./Pages/almacen/Salida";
import Despacho from './Pages/Despacho'
import Home from '../src/Pages/Home'
import Route404 from '../src/Pages/Route404'
import Login from '../src/Pages/Login'
import ProtectedRoute from "./utils/ProtectedRoutes"
import NuevoProducto from "../src/components/despacho/NuevoProducto/NuevoProducto.jsx"
import Stock from "../src/components/despacho/Stock/Stock.jsx"
import Naves from "../src/components/despacho/Naves/Naves.jsx"
import Restopage from "./Pages/almacen/Restopage";
import Incidencia from "./Pages/almacen/Incidencia";
import ImpresionQR from "./Pages/impresion/ImpresionQR";
import ScanCodigo from "./Pages/almacen/ScanCodigo";
import Estadisticas from "./Pages/Estadisticas";
import Logout from "./Pages/Logout";

function App() {
  return(
  <div>
    <Routes>
      <Route path="*" element={<Route404 />} />
      <Route path="/" element={<Home />} />
      <Route path="login" element={<Login />} />
      <Route path="logout" element={<Logout />} />
      <Route path="almacen" element={
        <ProtectedRoute role={['admin','despacho','almacen']}>
          <Almacen />
        </ProtectedRoute>
      }/>
      <Route path="almacen/localizar" element={
        <ProtectedRoute role={['admin','despacho','almacen']}>
         <Localizar />
        </ProtectedRoute>
      }/>
      <Route path="almacen/entrada" element={
        <ProtectedRoute role={['admin','despacho','almacen']}>
         <Entrada />
        </ProtectedRoute>
      }/>
      <Route path="almacen/salida" element={
        <ProtectedRoute role={['admin','despacho','almacen']}>
         <Salida />
        </ProtectedRoute>
      }/>
      <Route path="almacen/incidencia" element={
        <ProtectedRoute role={['admin','despacho','almacen']}>
         <Incidencia />
        </ProtectedRoute>
      }/>
      <Route path="almacen/resto" element={
        <ProtectedRoute role={['admin','despacho','almacen']}>
         <Restopage />
        </ProtectedRoute>
      }/>
      <Route path="almacen/scan" element={
        <ProtectedRoute role={['admin','despacho','almacen']}>
         <ScanCodigo />
        </ProtectedRoute>
      }/>
      <Route path="despacho" element={
        <ProtectedRoute role={['admin','despacho']}>
         <Despacho />
        </ProtectedRoute>
      }/>
      <Route path="despacho/nuevo_producto" element={
        <ProtectedRoute role={['admin','despacho']}>
         <NuevoProducto />
        </ProtectedRoute>
      }/>
      <Route path="despacho/stock" element={
        <ProtectedRoute role={['admin','despacho']}>
         <Stock />
        </ProtectedRoute>
      }/>
      <Route path="despacho/naves" element={
        <ProtectedRoute role={['admin','despacho']}>
         <Naves />
        </ProtectedRoute>
      }/>
      <Route path="despacho/estadisticas" element={
        <ProtectedRoute role={['admin','despacho']}>
         <Estadisticas />
        </ProtectedRoute>
      }/>
      <Route path="impresion/qr" element={
        <ProtectedRoute role={['admin','despacho']}>
         <ImpresionQR />
        </ProtectedRoute>
      }/>
    </Routes>
  </div>
  )
}

export default App;
