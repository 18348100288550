import "./ImpresionesPopup.css"
import axios from "axios";
import { apiRoot } from "../../../utils/utils";
import { handleAxiosError } from "../../../utils/utils";
import { Link } from "react-router-dom";

function ImpresionesPopup({setImpresionesPopup, impresiones, setImpresiones, productos}){
    function closePopup(){
        setImpresionesPopup(false);
    }
    function avoidPropagation(e){
        e.stopPropagation();
    }
    function returnCantidadDelPaletFromProductoId(idProducto, codigoBarraDelPalet){     
        if(productos.find(element => element.id == idProducto))
        {
            if(productos.find(element => element.id == idProducto).acf.ubicaciones)
            {
                if(productos.find(element => element.id == idProducto).acf.ubicaciones.find((element => element.codigo_barra == codigoBarraDelPalet))){
                    return productos.find(element => element.id == idProducto).acf.ubicaciones.find((element => element.codigo_barra == codigoBarraDelPalet)).cantidad;
                }
                else{
                    return "?";
                }
            }
        }
    }

    function returnNombreDelProductoFromProductoId(idProducto){
        if(productos.find(element => element.id == idProducto))
        {
            let tempProducto = productos.find(element => element.id == idProducto);
            return tempProducto.acf.medidas +" - "+tempProducto.title.rendered+" - "+tempProducto.acf.elemento;
        }
        else{
            return "id del producto existante en el registro en DB de esa impresion?";
        }
    }

    function deleteImpresion(id)
    {
        let newImpresiones = impresiones;
        newImpresiones.splice(newImpresiones.findIndex(element => element.id == id),1);
        //on modifie dans la base de données
        axios.delete(apiRoot+"/wp/v2/impresiones/"+id)
        .then(function (response) {
            console.log("Deleted impresiones response:",response);
            if(impresiones.length>0)
            {
                setImpresiones(newImpresiones => [...newImpresiones]); //on modifie le state impresiones (le spread permet de "refresh le state" sinon il n'est pas réellement pris en considération tout de suite)
            }
            else
            {
                setImpresiones(false); //on modifie le state impresiones
                window.location.reload(true);
            }
            
        })
        .catch(function (error) {
            handleAxiosError(error);
        });
    }
    return (
        <div id="impresionesPopupContainer" onClick={() => closePopup()}>
            <div id="impresionesDetails">
                <h2 className="blue">Impresiones</h2>
                <table id="tableImpresiones" onClick={(e)=>{avoidPropagation(e)}}>
                    <thead>
                        <tr>
                            <th>Producto</th>
                            <th>Ubicación</th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            impresiones&&
                            (
                                impresiones.map((impresion)=>{
                                    return(
                                        <tr key={impresion.id}>
                                            <td className="producto">{returnNombreDelProductoFromProductoId(impresion.acf.producto)}</td>
                                            <td className="ubicacion">{impresion.acf.ubicacion}</td>
                                            <td className="qr">
                                                <Link 
                                                    target="_blank"
                                                    to={{ pathname: '/impresion/qr', search: "?qr="+impresion.acf.barCode+"&ubicacion="+impresion.acf.ubicacion+"&producto="+returnNombreDelProductoFromProductoId(impresion.acf.producto)+"&cantidad="+returnCantidadDelPaletFromProductoId(impresion.acf.producto,impresion.acf.barCode)}}
                                                >🖨️</Link>
                                                </td>
                                            <td className="deleteMovimiento" onClick={()=>{deleteImpresion(impresion.id)}}>❌</td>
                                        </tr>
                                    )
                                })
                            )
                        }
                    </tbody>
                </table>
            </div>            
        </div>
    )
}
export default ImpresionesPopup;